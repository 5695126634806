import SignInCard from "../components/SignInCard";
import React, { Component } from "react";

export default class SignInPage extends Component {
  render() {
    return (
      <>
        <SignInCard />
      </>
    );
  }
}

import React from "react";
import { Grid } from "@material-ui/core";
import Loader from "./Loader";

export default function LoadingCard(props) {
  return (
    <div className="commonCard" id={props.id}>
      <div>
        <Grid container>
          <Grid item md={12}>
            <Loader />
            <h2 className="heading3">Hang on</h2>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

import React, { Fragment, useState, useContext } from "react";
import { Header, InfoSection } from "../../../shared_elements";
import { Grid, Container } from "@material-ui/core";
import styles from "../../../assets/styles/faq.module.scss";
import { staticImages } from "../../../utils";
import { MobileView, BrowserView } from "../../../shared_elements";
import { LanguageContext } from "../../../shared_elements/multiLanguage/containers/language";

export default function Faq() {
  const [qId, SetQid] = useState("");
  const languageContext = useContext(LanguageContext);

  const { faq } = languageContext.dictionary.routes;
  const handleQid = (id) => {
    if (id !== qId) {
      SetQid(id);
    } else {
      SetQid("");
    }
  };
  return (
    <section className={styles.faqPage}>
      <div className={styles.faqContainer}>
        <Container maxWidth="md" className={styles.faqWrapper}>
          <Grid container direction="column">
            {/* Web view */}
            <BrowserView>
              <Grid container className={`flexCentered ${styles.faqHeader}`}>
                <Grid item md={1} sm={1}>
                  <img
                    src={staticImages.faqGroup}
                    alt="faq"
                    className={styles.faqIcon}
                  ></img>
                </Grid>
                <Grid item md={11} sm={11}>
                  <Header content={faq.header} />
                </Grid>
              </Grid>

              {faq.sub_section.map((subSection, i) => {
                return (
                  <Fragment key={i}>
                    <Grid item md={12} sm={12}>
                      <h4 className="title">{subSection.title}</h4>
                    </Grid>
                    {subSection.questions.map((faqData, i) => {
                      return (
                        <Fragment key={faqData.question}>
                          <Grid item className={styles.faqDescription}>
                            <InfoSection
                              heading={faqData.question}
                              subheading={faqData.sub}
                              content={faqData.answer}
                            />
                          </Grid>
                          <Grid item>
                            <div className={styles.vrline}></div>
                          </Grid>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })}
            </BrowserView>
            {/* Mobile view */}
            <MobileView>
              <Grid container className={`${styles.faqHeader}`}>
                <Grid item md={11} xs={12}>
                  <Header content="FAQ" />
                </Grid>
              </Grid>

              {faq.sub_section.map((subSection, i) => {
                return (
                  <Fragment key={i}>
                    {subSection.title && (
                      <Grid item md={12} sm={12}>
                        <h4 className="title">{subSection.title}</h4>
                      </Grid>
                    )}

                    {subSection.questions.map((faqData, i) => {
                      return (
                        <Fragment key={faqData.question}>
                          <Grid
                            item
                            className={`${styles.questionWrapper}`}
                            onClick={() => handleQid(faqData.question)}
                          >
                            <InfoSection
                              heading={faqData.question}
                              faq={true}
                              subheading={
                                faqData.question === qId ? faqData.sub : ""
                              }
                              content={
                                faqData.question === qId ? faqData.answer : ""
                              }
                            />
                          </Grid>
                          <Grid item>
                            <div className={styles.vrline}></div>
                          </Grid>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })}
            </MobileView>
          </Grid>
        </Container>
      </div>
    </section>
  );
}

import React, { useEffect, useState } from "react";
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { Calendar } from "../../../shared_elements";
import { getCookie } from "../../../shared_elements/cookie";
import { staticImages } from "../../../utils";
import styles from "../../../assets/styles/userdetails.module.scss";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import firebase from "../../../shared_elements/firebase/firebase";
import { LoadingCard } from "../../../shared_elements";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F0F0F0",
    color: "#777777",
    fontSize: 11,
    height: "25px",
    padding: "0.1px",
    border: 0,
  },
  body: {
    fontSize: 16,
    fontFamily: "Ubuntu",
    fontWeight: 400,
    borderBottom: "1px solid #F0F0F0",
    paddingLeft: "none",
  },
}))(TableCell);

const StyledCalendar = withStyles(() => ({
  body: {
    fontSize: 16,
    fontFamily: "Ubuntu",
    fontWeight: 400,
    borderBottom: "1px solid #F0F0F0",
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    padding: "0px",
  },
}))(TableRow);

const useStyles = makeStyles({
  pagination: {
    color: "#636363",
  },
});

function UserScoreTable(props) {
  const [page, setPage] = useState(1);
  const [assessment, Setassessment] = useState([]);
  const [loading, SetLoading] = useState(true);
  const isMobile = !useMediaQuery("(min-width:600px)");
  const handleChangePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    let isFetched = true;
    if (isFetched) {
      const uid = getCookie("uid");
      if (props.userdetail && uid) {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("assessments")
          .orderBy("time", "desc")
          .get()
          .then((doc) => {
            let mapData = doc.docs.map((doc) => {
              return { id: doc.id, ...doc.data() };
            });
            mapData = mapData.sort(function (x, y) {
              return new Date(y["date"]) - new Date(x["date"]);
            });
            Setassessment(mapData);
          });
      }
    }
    setTimeout(() => {
      SetLoading(false);
    }, 2000);
    return () => (isFetched = false);
  }, [props.userdetail]);

  const classes = useStyles();
  return assessment && assessment.length < 1 && loading ? (
    <LoadingCard id="score" />
  ) : (
    <div className={styles.userScoreTable}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">DATE</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="center">SCORE</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessment.length !== 0 &&
              assessment.slice(7 * (page - 1), page * 7).map((assessment) => (
                <StyledTableRow
                  key={assessment.time + assessment.date + assessment.id}
                >
                  <StyledCalendar align="center">
                    <div align="center">
                      <Calendar
                        content={assessment.day}
                        className={styles.calendar}
                      />
                    </div>
                  </StyledCalendar>
                  <StyledTableCell>
                    {assessment.date}
                    <br />
                    <p className="label">{assessment.time}</p>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>{assessment.totalPoints}/52</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Link className="link" to={`pdf/${assessment.id}`}>
                      <img
                        src={staticImages.pdfIcon}
                        alt="pdf"
                        className={styles.tableIcons}
                      ></img>
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={styles.paginationContainer}
      >
        {assessment.length < 2 && (
          <>
            <Grid item md={12} className="textCenter">
              <img
                src={
                  isMobile
                    ? staticImages.mobileCoachMarker
                    : staticImages.coachMarker
                }
                alt={"Welcome to myopal"}
                className={styles.coachMarker}
              ></img>
            </Grid>
            <Grid item md={12} xs={12}>
              <Link to="/assessment">
                <button className="primaryBtn">START ASSESSMENT</button>
              </Link>
            </Grid>
          </>
        )}
        {assessment.length > 7 && (
          <Grid item md={12}>
            <Pagination
              count={Math.floor(assessment.length / 7 + 1)}
              shape="rounded"
              onChange={handleChangePage}
              className={styles.pagination}
              hidePrevButton
              hideNextButton
            ></Pagination>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userdetail: state.userdetail,
  };
};

export default connect(mapStateToProps)(UserScoreTable);

import { createStore, applyMiddleware } from "redux";
import { saveStore } from "./globalStore";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

export default function configureStore() {
  const store = createStore(rootReducer, {}, applyMiddleware(thunk));
  saveStore(store);
  return store;
}

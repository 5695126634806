import React from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import parse from "html-react-parser";

function InfoSection(props) {
  return (
    <>
      {Array.isArray(props.content) ? (
        <Grid container spacing={0}>
          {props.heading && (
            <Grid item xs={props.about ? 12 : 10} md={10}>
              <h2 className="heading2">{props.heading}</h2>
            </Grid>
          )}

          {props.faq && props.content ? (
            <Grid item xs={2} className="textRight">
              <ExpandLessIcon />
            </Grid>
          ) : (
            props.faq && (
              <Grid item xs={2} className="textRight">
                <ExpandMoreIcon />
              </Grid>
            )
          )}
          {(props.content || props.subheading) && (
            <>
              <Grid item xs={12}>
                <h3 className="paragraph">{props.subheading}</h3>
              </Grid>
              <ul>
                {Array.isArray(props.content) &&
                  props.content.map((data) => {
                    return (
                      <Grid item key={data} xs={12} sm={12} md={12}>
                        <li className="paragraph">
                          <span>
                            <p>{parse(data)}</p>
                          </span>
                        </li>
                      </Grid>
                    );
                  })}
              </ul>
            </>
          )}
        </Grid>
      ) : (
        <Grid container spacing={0} alignItems="center">
          {props.heading && (
            <Grid item md={12} xs={props.about ? 12 : 10} sm={12}>
              <h2 className="heading2">{props.heading}</h2>
            </Grid>
          )}

          {props.faq && props.content ? (
            <Grid item xs={2} className="textRight">
              <ExpandLessIcon />
            </Grid>
          ) : (
            props.faq && (
              <Grid item xs={2} className="textRight">
                <ExpandMoreIcon />
              </Grid>
            )
          )}
          {props.content && (
            <Grid item md={12} xs={12} sm={12}>
              <p className="paragraph">{parse(props.content)}</p>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

InfoSection.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

InfoSection.defaultProps = {
  heading: "",
  subheading: "",
};

export default InfoSection;

import React, { useState, createContext, useContext } from "react";
import parse from "html-react-parser";
import { languageOptions, dictionaryList } from "../diffLang";

export const LanguageContext = createContext({
  userLanguage: "en",
  dictionary: dictionaryList.en,
});

export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState("en");

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : "en";
      setUserLanguage(newLanguage);
      window.localStorage.setItem("rcml-lang", newLanguage);
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}

export function Content({ path, raw }) {
  const languageContext = useContext(LanguageContext);

  const dataFromJson = path
    .split(".")
    .reduce((p, c) => p && (p[c] || null), languageContext.dictionary);
  let htmlParsedData;
  if (dataFromJson instanceof Array) {
    htmlParsedData = dataFromJson.map((data) => {
      return parse(data);
    });
  } else {
    htmlParsedData = parse(dataFromJson);
  }

  if (typeof htmlParsedData === "string" || "html") {
    return htmlParsedData;
  }

  return "data not found";
}

import React, { useEffect, Fragment } from "react";
import styles from "../../../assets/styles/assessment.module.scss";
import { Grid, useMediaQuery } from "@material-ui/core";
import { staticImages, athete } from "../../../utils";
import { CongratsIcon, CongratsIconSmall } from "../../../shared_elements";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RESET_TIMER } from "../../../shared_elements/redux/timer/actions";
import { ADD_USERDETAIL } from "../../../shared_elements/redux/userdetails/actions";
import firebase from "../../../shared_elements/firebase/firebase";
import moment from "moment";
import { getCookie } from "../../../shared_elements/cookie";
import { CLEAR_RESPONSE } from "../../../shared_elements/redux/responses/actions";
import { BrowserView, MobileView } from "../../../shared_elements";

function Congrats(props) {
  const uid = getCookie("uid");
  const { lastMinuteUpdate } = props.userdetail;
  const isMobile = !useMediaQuery("(min-width:600px)");

  useEffect(() => {
    let athlete = athete(props.points, props.userdetail.gender);

    if (!uid && props.responses && props.responses.length === 0)
      return props.history.push("/assessment");
    const lastMinute = Number(moment().format("mm"));
    if (
      props.userdetail &&
      Number(lastMinuteUpdate) !== lastMinute &&
      Number(lastMinuteUpdate) !== lastMinute - 1 &&
      Number(lastMinuteUpdate) !== lastMinute + 1 &&
      !props.userdetail.lastAssesPushed
    ) {
      props.dispatch({ type: RESET_TIMER });
      const day = moment().format("DD"),
        date = moment().format("ll"),
        time = moment().format("LT"),
        minute = moment().format("mm");

      props.dispatch({
        type: ADD_USERDETAIL,
        payload: {
          lastDayAsses: day,
          lastDateAsses: date,
          lastTimeAsses: time,
          lastMinuteUpdate: minute,
        },
      });

      const formData = {
        response: props.responses,
        totalPoints: props.points,
        atlete: athlete,
        day: day,
        date: date,
        time: time,
      };
      if (props.userdetail && props.userdetail.name && uid) {
        const userRef = firebase.firestore().collection("users").doc(uid);
        userRef.get().then((docSnap) => {
          if (docSnap.exists) {
            userRef
              .collection("assessments")
              .add(formData)
              .then((success) => {
                if (success) {
                  props.dispatch({
                    type: ADD_USERDETAIL,
                    payload: {
                      lastAssesPushed: true,
                      lastAthlete: athlete,
                    },
                  });
                }
              });
            userRef
              .update({ lastAthlete: athlete })
              .then(() => {
                console.log("athlete updated");
              })
              .catch((err) => {
                console.log(err, "error updating athlete");
              });
          } else {
            props.dispatch({
              type: ADD_USERDETAIL,
              payload: {
                lastAssesPushed: false,
              },
            });
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleViewReport = () => {
    props.history.push("/pdf");
  };

  const handleRedo = () => {
    props.dispatch({ type: CLEAR_RESPONSE });
    props.dispatch({
      type: ADD_USERDETAIL,
      payload: {
        lastAssesPushed: false,
      },
    });
    props.history.push("/assessment");
  };

  const handleCreateAnAccount = () => {
    if (uid) {
      props.history.push("/user-profile");
    } else {
      props.history.push("/sign-in");
    }
  };
  return (
    <div className={styles.congratsCard}>
      <>
        <Grid container className={styles.blueContainer} justify="center">
          <Grid item md={12} container justify="center" xs={12} sm={12}>
            <Grid item className={styles.congratsImg}>
              {isMobile ? (
                <CongratsIconSmall
                  name={props.userdetail.name}
                  atlete={athete(props.points, props.userdetail.gender)}
                />
              ) : (
                <CongratsIcon
                  name={props.userdetail.name}
                  atlete={athete(props.points, props.userdetail.gender)}
                />
              )}
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <p className="paragraph">
                You’ve successfully completed the assessment.
              </p>
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <p className="heading1">Your Total Score: {props.points}/52</p>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            container
            justify="center"
            className={styles.btnContainer}
          >
            <button className="primaryBtn" onClick={handleViewReport}>
              VIEW REPORT
            </button>
          </Grid>
        </Grid>
      </>
      <>
        <Grid container className={styles.whiteContainer}>
          <Grid item xs={12} sm={12} container justify="center">
            <h6 className="paragraph">What else ?</h6>
          </Grid>
          {/* Web View */}
          <BrowserView>
            <Grid
              item
              md={12}
              container
              direction="row"
              justify="center"
              spacing={2}
              xs={12}
              sm={12}
            >
              <Grid item onClick={() => props.history.push("/doctor")}>
                <div className={styles.squareBox}>
                  <Grid
                    item
                    container
                    direction={isMobile ? "row" : "column"}
                    sm={12}
                    alignItems="center"
                  >
                    <Grid item>
                      <img src={staticImages.doctorBlue} alt="doc"></img>
                    </Grid>

                    <Grid item>
                      <p className="paragraph">Consult a doctor</p>
                    </Grid>
                    <Grid item>
                      <p className="label">Recommended in anycase.</p>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item>
                <div className={styles.squareBox}>
                  <Grid
                    item
                    container
                    direction={isMobile ? "row" : "column"}
                    alignItems="center"
                    onClick={handleCreateAnAccount}
                  >
                    {props.userdetail && uid && (
                      <Fragment>
                        {props.userdetail.imgUrl ? (
                          <Grid item>
                            <img
                              src={props.userdetail.imgUrl}
                              alt="doc"
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: 100,
                              }}
                            ></img>
                          </Grid>
                        ) : (
                          <Grid item onClick={handleCreateAnAccount}>
                            <img
                              src={staticImages.userProfileBlue}
                              alt="doc"
                            ></img>
                          </Grid>
                        )}
                        <Grid item>
                          <p className="paragraph">Go to your Account</p>
                        </Grid>
                        <Grid item>
                          <p className="label">See your account details</p>
                        </Grid>
                      </Fragment>
                    )}
                    {props.userdetail && !uid && (
                      <Fragment>
                        <Grid item>
                          <img
                            src={staticImages.userProfileBlue}
                            alt="doc"
                          ></img>
                        </Grid>
                        <Grid item>
                          <p className="paragraph">Create an account</p>
                        </Grid>
                        <Grid item>
                          <p className="label">Store your scores</p>
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </BrowserView>

          {/* Mobile View */}
          <MobileView>
            <Grid item xs={12} sm={12} container justify="center" spacing={2}>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justify="center"
                onClick={() => props.history.push("/doctor")}
              >
                <Grid container xs={12} className="flexCentered">
                  <Grid item xs={5} className="textRight">
                    <img src={staticImages.doctorBlue} alt="doc"></img>
                  </Grid>
                  <Grid item xs={7}>
                    <p className="paragraph">Consult a doctor</p>
                    <p className="label">Recommended in anycase.</p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justify="center"
                onClick={handleCreateAnAccount}
              >
                {props.userdetail && uid && (
                  <Fragment>
                    {props.userdetail.imgUrl ? (
                      <Grid
                        item
                        xs={5}
                        className={`textRight ${styles.congratsIcon}`}
                      >
                        <img
                          src={props.userdetail.imgUrl}
                          alt="doc"
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 100,
                          }}
                        ></img>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={5}
                        className={`textRight ${styles.congratsIcon}`}
                      >
                        <img src={staticImages.userProfileBlue} alt="doc"></img>
                      </Grid>
                    )}
                    <Grid item xs={7}>
                      <p className="paragraph">Go to your Account</p>
                      <p className="label">See your account details</p>
                    </Grid>
                  </Fragment>
                )}
                {props.userdetail && !uid && !props.userdetail.imgUrl && (
                  <Fragment>
                    <Grid
                      item
                      xs={5}
                      className={`textRight ${styles.congratsIcon}`}
                    >
                      <img src={staticImages.userProfileBlue} alt="doc"></img>
                    </Grid>
                    <Grid item xs={7}>
                      <p className="paragraph">Create an account</p>
                      <p className="label">Store your scores</p>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </MobileView>
          <Grid item sm={12} md={12} xs={12} container justify="center">
            <h5 className="label" onClick={handleRedo}>
              Redo the assessment
            </h5>
          </Grid>
        </Grid>
      </>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    points:
      state.responses.length === 0
        ? 0
        : state.responses
            .map((res) => res.points)
            .reduce((sum, agg) => sum + agg, 0),

    userdetail: state.userdetail,
    responses: state.responses,
  };
};

export default connect(mapStateToProps)(withRouter(Congrats));

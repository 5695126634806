import React from "react";

export default function Girl(props) {
  return (
    <div>
      <svg
        width="14"
        height="26"
        viewBox="0 0 14 26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.0145 3.53448C9.32464 2.20269 8.49644 0.871651 7.16466 0.561508C5.83288 0.251365 4.50183 1.07957 4.19169 2.41135C3.88155 3.74313 4.70975 5.07417 6.04153 5.38432C7.37331 5.69446 8.70436 4.86626 9.0145 3.53448Z"
          fill={props.color}
        />
        <path
          d="M9.39804 6.53181C9.2711 6.4511 9.15279 6.39469 9.04544 6.35551L9.04466 6.35316C9.04466 6.35316 8.23524 5.94336 6.60467 5.94336C4.97409 5.94336 4.16547 6.35316 4.16547 6.35316L4.16469 6.35394C4.05734 6.3939 3.93824 6.4511 3.8113 6.53181C3.30278 6.85777 2.6822 7.52535 2.01932 8.95691C1.35721 10.3932 0.650447 12.6122 0.0118509 16.1162C-0.0594524 16.5049 0.198337 16.8778 0.587763 16.9484C0.630859 16.9562 0.673954 16.9609 0.716266 16.9609C1.05554 16.9609 1.35643 16.718 1.41911 16.3732C2.17367 12.2141 3.02853 9.98806 3.67967 8.84799L1.83361 18.347H4.21953V24.8999C4.21953 25.5071 4.71161 26 5.31964 26C5.9269 26 6.42054 25.5071 6.42054 24.8999V18.347H6.78724V24.8999C6.78724 25.5071 7.28009 26 7.88813 26C8.49539 26 8.98824 25.5071 8.98824 24.8999V18.347H11.3734L9.52732 8.85034C9.63154 9.03056 9.73967 9.23898 9.8525 9.47954C10.4504 10.7465 11.1524 12.8786 11.7879 16.374C11.8513 16.7188 12.1522 16.9617 12.4915 16.9617C12.5338 16.9617 12.5761 16.9578 12.62 16.9499C13.0087 16.8786 13.2664 16.5064 13.1959 16.117C12.3426 11.4463 11.3749 9.05642 10.542 7.76512C10.1267 7.11947 9.73654 6.74729 9.39804 6.53181Z"
          fill={props.color}
        />
      </svg>
    </div>
  );
}

import { useCallback, useRef, useState, useEffect } from "react";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default function useAddressPredictions(input) {
  const [predictions, setPredictions] = useState([]);
  const autocomplete = useRef();

  if (!autocomplete.current) {
    autocomplete.current = new window.google.maps.places.AutocompleteService();
  }

  function getPlacePredictions(input) {
    let options = {
      types: ["(cities)"],
      componentRestrictions: { country: "in" },
    };
    autocomplete.current.getPlacePredictions(
      { input, options },
      (predictions) => {
        setPredictions(predictions.map((prediction) => prediction.description));
      }
    );
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    []
  );

  useEffect(() => {
    if (input && input.length > 2) {
      getPlacePredictions(input);
    }
  }, [input]);

  useEffect(() => {
    if (input && input.length > 2) {
      debouncedGetPlacePredictions(input);
    }
  }, [input, debouncedGetPlacePredictions]);

  return predictions;
}

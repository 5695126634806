import { firebaseFirestore } from "./firebaseFunc";

function fireDbFetch(isSubscribed, collection, callback) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(callback());
    }, 10000);

    firebaseFirestore
      .collection(collection)
      .get()
      .then((querySnapshot) => {
        if (isSubscribed) {
          const data = querySnapshot.docs.map((doc) => doc.data());
          if (data && data.length !== 0) {
            resolve(data[0]);
            clearTimeout(timeoutId);
          }
        }
      })
      .catch((err) => {
        if (err) {
          // console.log(err);
          clearTimeout(timeoutId);
        }
      });
  });
}

// function fireDbAddDoc(data, collection1, doc, collection2) {
//   return firebaseFirestore
//     .collection(collection1)
//     .doc(doc)
//     .collection(collection2)
//     .add(data)
//     .then(() => {
//       // console.log("data has been saved");
//     })
//     .catch(() => {
//       // console.log("error saving data");
//     });
// }

export { fireDbFetch };

import React, { Component } from "react";
import Congrats from "../components/Congrats";

export default class CongratsPage extends Component {
  render() {
    return (
      <>
        <Congrats />
      </>
    );
  }
}

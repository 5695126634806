import React from "react";
import { Grid, Container, useMediaQuery } from "@material-ui/core";
import { staticImages } from "../utils";
import {
  AboutMyOpal,
  FaqCard,
  CopyRights,
  NavBar,
  BrowserView,
} from "../shared_elements";

export default function SomethingWrong(props) {
  const isMobile = !useMediaQuery("(min-width:600px)");
  return (
    <>
      {!isMobile && <NavBar visible={false} />}
      <main>
        <section className="sectionPage">
          <div className="wrongPageContainer">
            <Container maxWidth="md">
              <Grid container justify="center" spacing={3}>
                <Grid item md={8} sm={12}>
                  <div className="serverDown">
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <img
                          src={staticImages.someThingWrong}
                          alt="server down"
                        />
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <h2 className="heading2">Oops! Something is wrong </h2>
                      </Grid>
                      <Grid item md={12} sm={12} className="content">
                        <p className="paragraph">
                          Seems like something broke somewhere. Please try again
                          after some time.
                        </p>
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <button
                          className="primaryBtn"
                          onClick={() => {
                            props.history.push("/");
                            window.location.reload();
                          }}
                        >
                          TRY AGAIN
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <BrowserView>
                  <Grid item md={4}>
                    <Grid
                      container
                      justify={"center"}
                      spacing={3}
                      direction="row"
                    >
                      <Grid item>
                        <AboutMyOpal visible={false} />
                      </Grid>
                      <Grid item>
                        <FaqCard visible={false} />
                      </Grid>
                      <Grid item>
                        <CopyRights visible={false} />
                      </Grid>
                    </Grid>
                  </Grid>
                </BrowserView>
              </Grid>
            </Container>
          </div>
        </section>
      </main>
    </>
  );
}

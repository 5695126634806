import React from "react";
import ProgressBar from "../../../shared_elements/ProgressBar";
import styles from "../../../assets/styles/assessment.module.scss";
import { Grid } from "@material-ui/core";

export default function AssessmentHeader(props) {
  return (
    <div className={styles.assessmentHeader}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={`${styles.assessmentWrapper} flexCentered`}
      >
        <Grid item md={3} xs={3} className={`textLeft ${styles.headerLeft}`}>
          <p className={`${styles.header} heading5`}>STEP {props.percent}</p>
          <p className={`${styles.subheader} smallLabel`}>
            OUT OF {props.maxExercise}
          </p>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          container
          justify="center"
          className={styles.progressBar}
        >
          <ProgressBar
            percent={Number((props.percent - 1) * 10)}
            maxQues={props.maxExercise}
          />
        </Grid>
        <Grid item md={3} xs={3} className={`textRight ${styles.headerRight}`}>
          <p className={`${styles.header} heading5`}>{props.points} POINTS</p>
          <p className={`${styles.subheader} smallLabel`}>Total Score</p>
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useContext, Fragment } from "react";
import { Header, InfoSection, MobileView } from "../../../shared_elements";
import styles from "../../../assets/styles/aboutpage.module.scss";
import { Grid, Container } from "@material-ui/core";
import { staticImages } from "../../../utils";
import { CSSTransition } from "react-transition-group";
import { LanguageContext } from "../../../shared_elements/multiLanguage/containers/language";

export default function About() {
  const languageContext = useContext(LanguageContext);

  const { about } = languageContext.dictionary.routes;

  return (
    <Fragment>
      <section className={styles.aboutSection}>
        <Container maxWidth="md" className={styles.aboutWrapper}>
          <Grid container direction="column" spacing={1}>
            <Grid item md={12}>
              <CSSTransition timeout={200} classNames="fade-appear">
                <div className={styles.imgWrapper}>
                  <img
                    src={staticImages.childrenWide}
                    className={styles.imgCover}
                    alt="children cover"
                  />
                </div>
              </CSSTransition>
            </Grid>
            <Grid item md={12}>
              <Grid
                container
                direction="column"
                className={styles.aboutdesc}
                spacing={2}
              >
                <Grid item md={12}>
                  <Header content={about.header} />
                </Grid>
                {about.questions.map((aboutData) => {
                  return (
                    <Grid item md={12} key={aboutData.question}>
                      <InfoSection
                        heading={aboutData.question}
                        content={aboutData.answer}
                        about={true}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <MobileView>
            <div style={{ padding: "20px" }}></div>
          </MobileView>
        </Container>
      </section>
    </Fragment>
  );
}

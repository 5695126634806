import React from "react";
import styles from "../assets/styles/progress.module.scss";
import PropTypes from "prop-types";

function ProgressBar(props) {
  return (
    <div className={styles.progressbar}>
      <div className={styles[`progress${props.maxQues}`]}>
        <div className={styles.bar}>
          <div className={styles[`percent${props.percent}`]}></div>
          {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140].map(
            (num) => {
              return <div className={styles[`ref${num}`]} key={num}></div>;
            }
          )}
        </div>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  percent: PropTypes.number,
};

ProgressBar.defaultProps = {
  percent: 0,
};

export default ProgressBar;

import UserDetailCard from "../components/UserDetailCard";
import UserScoreCard from "../components/UserScoreCard";
import { Grid, Container, useMediaQuery } from "@material-ui/core";
import React from "react";

export default function UserProfilePage() {
  const isMobile = !useMediaQuery("(min-width:600px)");

  return (
    <section className="userDetailCard">
      <Container maxWidth="md">
        <Grid container spacing={2}>
          {!isMobile && (
            <Grid item md={8} sm={12} xs={12} className="userScoreTab">
              <UserScoreCard />
            </Grid>
          )}
          <Grid item md={4} sm={12} xs={12}>
            <UserDetailCard />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

import React, { useContext, Fragment } from "react";
import { Header, InfoSection, MobileView } from "../../../shared_elements";
import styles from "../../../assets/styles/aboutpage.module.scss";
import { Grid, Container } from "@material-ui/core";
import { LanguageContext } from "../../../shared_elements/multiLanguage/containers/language";

export default function Privacy() {
  const languageContext = useContext(LanguageContext);

  const { policy } = languageContext.dictionary.routes;

  return (
    <Fragment>
      <section className={styles.aboutImgSection}></section>
      <section className={styles.aboutSection}>
        <Container maxWidth="md" className={styles.aboutWrapper}>
          <Grid container direction="column" spacing={1}>
            <Grid item md={12}>
              <Grid
                container
                direction="column"
                className={styles.aboutdesc}
                spacing={2}
              >
                {policy && policy.header && (
                  <Grid item md={12}>
                    <Header content={policy.header} />
                  </Grid>
                )}
                {policy.questions.map((policyData, i) => {
                  return (
                    <Grid
                      item
                      md={12}
                      key={policyData.question + i + policyData.answer}
                    >
                      <InfoSection
                        heading={policyData.question}
                        content={policyData.answer}
                        subheading={policyData.sub}
                        about={true}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <MobileView>
            <div style={{ padding: "20px" }}></div>
          </MobileView>
        </Container>
      </section>
    </Fragment>
  );
}

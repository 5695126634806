import React from "react";
import styles from "../assets/styles/loader.module.scss";

export default function Loader() {
  return (
    <div className={styles.bar}>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((key) => {
        return <div key={key}></div>;
      })}
    </div>
  );
}

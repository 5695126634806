import React, { useState, useCallback, useEffect } from "react";
import styles from "../../../assets/styles/userdetails.module.scss";
import {
  Grid,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Modal,
  Button,
  IconButton,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Boy from "../containers/icons/Boy";
import Girl from "../containers/icons/Girl";
import DatePicker from "react-datepicker";
import Cropper from "react-easy-crop";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

import "react-datepicker/dist/react-datepicker.css";
import firebase from "../../../shared_elements/firebase/firebase";
import { getCookie, removeCookie } from "../../../shared_elements/cookie";

import { CustomSlider, MobileView } from "../../../shared_elements";
import { staticImages } from "../../../utils";

import { CLEAR_RESPONSE } from "../../../shared_elements/redux/responses/actions";
import {
  RESET_USERDETAIL,
  ADD_USERDETAIL,
} from "../../../shared_elements/redux/userdetails/actions";

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    "&:focus": {
      outline: "none",
    },
    maxWidth: "967px",
    maxHeight: "560px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    boxShadow: " 0px 0px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    "@media (max-width:600px)": {
      // eslint-disable-line no-useless-computed-key
      width: "100%",
      height: "100%",
      backgroundColor: "white",
    },
  },
  modalContainer: {
    padding: 30,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width:600px)": {
      // eslint-disable-line no-useless-computed-key
      marginTop: "20px",
      paddingLeft: "5%",
      paddingRight: "7%",
      height: "100%",
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "gray",
      opacity: 0.98,
    },
  },
  root: {
    backgroundColor: "white",
  },
  cropperContainer: {
    position: "relative",
    minWidth: "200px",
    minHeight: "150px",
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  sliderLabel: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 65,
    },
  },
  header: {
    paddingTop: "20px",
  },
  slider: {
    padding: "22px 0px",
    marginLeft: 16,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      margin: "0 16px",
    },
  },
  sliderContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  btnContainer: {
    padding: "10px",
    textAlign: "center",
  },
  btn: {
    background: "#EFEFEF",
    border: "1px solid #B4B3B3",
    boxSizing: "border-box",
    borderRadius: " 4px",
    textAlign: "center",
    width: "129px",
    height: "40px",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const styleForModal = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    zIndex: "3000",
  },
  root: {
    backgroundColor: "white",
  },
}));

function UserDetailCard(props) {
  //media query
  const isMobile = !useMediaQuery("(min-width:600px)");
  //props destructure
  const uid = getCookie("uid");
  const { lastAthlete } = props.userdetail;

  const classes = useStyles();
  const modalClass = styleForModal();

  //handling form
  const [isEdit, SetIsEdit] = useState(false);

  const [deleteUserdataModal, SetDeleteUserdataModal] = useState(false);
  //user detail fields
  const [imgUrl, SetImgUrl] = useState(
    props.userdetail && props.userdetail.imgUrl ? props.userdetail.imgUrl : ""
  );
  const [name, SetName] = useState(
    props.userdetail && props.userdetail.name ? props.userdetail.name : ""
  );
  const [gender, SetGender] = useState(
    props.userdetail && props.userdetail.gender ? props.userdetail.gender : ""
  );

  const [dob, SetDob] = useState(
    props.userdetail &&
      props.userdetail.dob &&
      props.userdetail.dob.length !== 0
      ? new Date(props.userdetail.dob)
      : ""
  );

  const [city, SetCity] = useState(
    props.userdetail && props.userdetail.city ? props.userdetail.city : ""
  );
  const [phone, SetPhone] = useState(
    props.userdetail && props.userdetail.phone ? props.userdetail.phone : "+91"
  );

  const [age, SetAge] = useState(
    props.userdetail && props.userdetail.age ? props.userdetail.age : ""
  );

  useEffect(() => {
    if (dob) {
      const userAge = moment(dob).endOf("year").fromNow(true);
      // if(typeof userAge === "string")
      SetAge(userAge);
    }
  }, [dob]);

  //image cropper
  const [imageCropModal, setImageCropModal] = React.useState(false);

  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (name, uid) => {
      try {
        await getCroppedImg(imageSrc, croppedAreaPixels, 0, name, uid);
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageSrc, croppedAreaPixels]
  );

  const onSelectFile = async (e) => {
    setImageCropModal(true);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, name, uid) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const userImgName =
          name.length !== 0
            ? name.split(" ").join("").toLowerCase() + String(+new Date())
            : uid;
        var file = new File([blob], userImgName);
        const uploadTask = firebase
          .storage()
          .ref(`userImages/${userImgName}.png`)
          .put(file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            // error function
            console.log(error);
          },
          () => {
            firebase
              .storage()
              .ref("userImages")
              .child(`${userImgName}.png`)
              .getDownloadURL()
              .then((url) => {
                if (url) {
                  SetImgUrl(url);

                  setImageCropModal(false);
                  resolve(url);
                } else {
                  console.log("problem with image url");
                }
              });
          }
        );
      }, "image/png");
    });
  }

  //till here

  useEffect(() => {
    if (props.userdetail.name !== name) {
      SetName(props.userdetail.name);
    }
    if (props.userdetail.dob !== dob) {
      SetDob(new Date(props.userdetail.dob));
    }

    if (props.userdetail.gender !== gender) {
      SetGender(props.userdetail.gender);
    }

    if (props.userdetail.city !== city) {
      SetCity(props.userdetail.city);
    }

    if (props.userdetail.phone !== phone) {
      SetPhone(props.userdetail.phone);
    }

    if (props.userdetail.imgUrl !== imgUrl) {
      SetImgUrl(props.userdetail.imgUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userdetail]);

  const handleClose = () => {
    setImageCropModal(false);
    SetDeleteUserdataModal(false);
  };

  const handleFormSubmit = () => {
    const formData = {
      name,
      gender,
      dob: String(dob),
      phone,
      city,
      lastAthlete,
      age,
      imgUrl,
    };

    const userRef = firebase.firestore().collection("users").doc(uid);
    if (uid) {
      userRef.get().then((docSnap) => {
        if (docSnap.exists) {
          userRef.update(formData);
        } else {
          userRef.set(formData);
        }
        props.dispatch({ type: ADD_USERDETAIL, payload: formData });
      });
    } else {
      props.history.push("/sign-in");
      console.log("user is not registered or login , as uid is not available");
    }
    SetIsEdit(false);
  };

  const handleDeleteUserData = () => {
    const userRef = firebase.firestore().collection("users").doc(uid);
    const assessmentRef = userRef.collection("assessments");
    if (uid) {
      userRef.get().then((docSnap) => {
        if (docSnap.exists) {
          docSnap.ref
            .delete()
            .then(() => {
              removeCookie("uid");
              removeCookie("cookieExpiry");
              removeCookie("sessionExpiry");
              props.history.push("/");
              props.dispatch({ type: CLEAR_RESPONSE });
              props.dispatch({ type: RESET_USERDETAIL });
            })
            .catch((err) => {
              console.error(
                "Error removing the document from the array because" + err
              );
            });
        } else {
          console.error("No document of user found to delete it");
        }
      });

      assessmentRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref
              .delete()
              .then(() => {
                // console.log("doc deleted successfully");
              })
              .catch((err) => {
                console.log("error deleting the doc due to" + err);
              });
          });
        })
        .catch((err) => {
          console.error(
            "error in deleting the the assessment because of" + err
          );
        });

      SetDeleteUserdataModal(false);
    } else {
      console.error("user doesnot exist");
    }
  };

  const yearsBack = (year = 0) =>
    new Date(new Date().setFullYear(new Date().getFullYear() - year));
  return (
    <div className={styles.userDetailCard}>
      <Grid container>
        <MobileView>
          <Grid item container justify="flex-start" xs={6}>
            <h1 className="heading1">Profile</h1>
          </Grid>
        </MobileView>
        <Grid
          item
          container
          justify="flex-end"
          md={12}
          className={styles.edit}
          xs={6}
        >
          {!isEdit ? (
            <p className="smallLabel" onClick={() => SetIsEdit(true)}>
              EDIT
            </p>
          ) : (
            <div className={styles.editContainer}>
              <IconButton
                onClick={() => SetIsEdit(false)}
                className={styles.iconButton}
              >
                <CloseIcon className={styles.closeIcon} />
              </IconButton>
            </div>
          )}
        </Grid>
        <Grid item md={12} xs={12}>
          <Modal
            open={imageCropModal}
            hideBackdrop={true}
            onClose={handleClose}
            closeAfterTransition
            disableAutoFocus={true}
            className={classes.modal}
          >
            <div className={classes.paper}>
              <Grid container spacing={3} className={classes.modalContainer}>
                <Grid item md={12} xs={12}>
                  <h2
                    className="heading4"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    Change Profile Picture
                  </h2>
                  <Divider />
                </Grid>

                <Grid item md={6} xs={12}>
                  <div className={classes.cropperContainer}>
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      cropShape="round"
                      showGrid={false}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                  <div className={classes.sliderContainer}>
                    <CustomSlider
                      value={zoom}
                      min={1}
                      max={5}
                      step={0.05}
                      defaultValue={1}
                      initialValue={1}
                      valueLabelDisplay="off"
                      sendBackValue={(v) => setZoom(v)}
                    />
                  </div>
                </Grid>
                <Grid item md={6} xs={12} className="flexCentered">
                  <Grid item md={12} xs={12} container justify="center">
                    <label htmlFor="upload-photo">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        onChange={onSelectFile}
                      />
                      <div className={classes.btn} id="upload-photo">
                        Choose New Image
                      </div>
                    </label>
                  </Grid>
                  <Grid item className={classes.btnContainer} md={12} xs={12}>
                    <Button
                      className={"primaryBtn"}
                      onClick={() => showCroppedImage(name, uid)}
                    >
                      SAVE
                    </Button>
                  </Grid>
                  <Grid item className={classes.btnContainer} md={12} xs={12}>
                    <Button className={"secondaryBtn"} onClick={handleClose}>
                      CLOSE
                    </Button>
                  </Grid>
                </Grid>
                <Grid item md={12} container justify="center" xs={12}></Grid>
              </Grid>
            </div>
          </Modal>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          container
          className="userImageEditContainer"
          justify={isMobile ? "flex-start" : "center"}
        >
          {isEdit && imgUrl.length < 1 ? (
            <div className={styles.userImageContainer}>
              <label htmlFor="upload-photo">
                <input
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={onSelectFile}
                  disabled={!isEdit}
                />
                <div className={`${styles.circle} label`}>Upload Image</div>
              </label>
            </div>
          ) : (
            isEdit && (
              <>
                <input
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={onSelectFile}
                />
                <label htmlFor="upload-photo">
                  <div className={styles.picContainer} id="upload-photo">
                    <div className={styles.changeProfilePic} id="upload-photo">
                      <p id="upload-photo"> Change Photo</p>{" "}
                    </div>

                    <img src={imgUrl} alt={name} className={styles.avatar} />
                  </div>
                </label>
              </>
            )
          )}

          {imgUrl.length > 1
            ? !isEdit && (
                <img src={imgUrl} alt={name} className={styles.avatar} />
              )
            : !isEdit && (
                <img
                  src={staticImages.profileImg}
                  alt={name}
                  className={styles.avatar}
                />
              )}
          {!isEdit && !imgUrl.length > 1 && (
            <img
              src={staticImages.profileImg}
              alt={name}
              className={styles.avatar}
            />
          )}
        </Grid>
        <Grid
          item
          md={12}
          container
          spacing={2}
          className={`${styles.userInput} flexCentered`}
        >
          <Grid item md={2} xs={12} className={styles.textLabel}>
            <label>Name</label>
          </Grid>
          <Grid item md={10} xs={12} className={styles.inputContainer}>
            <input
              value={name}
              onChange={(e) => SetName(e.target.value)}
              disabled={!isEdit}
            ></input>
          </Grid>
          <Grid item md={2} xs={12} className={styles.textLabel}>
            <label>Gender</label>
          </Grid>
          <Grid item md={10} xs={12} className={styles.inputContainer}>
            <Select
              value={gender}
              variant="outlined"
              onChange={(e) => SetGender(e.target.value)}
              name="gender"
              disabled={!isEdit}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value={"male"}>
                <ListItemIcon>
                  <Boy />
                </ListItemIcon>
                <ListItemText primary="Boy" />
              </MenuItem>
              <MenuItem value={"female"}>
                <ListItemIcon>
                  <Girl />
                </ListItemIcon>
                <ListItemText primary="Girl" />
              </MenuItem>
            </Select>
          </Grid>
          <Grid item md={2} className={styles.textLabel} xs={12}>
            <label>DOB</label>
          </Grid>
          <Grid item md={6} className={styles.dateContainer} xs={8}>
            <DatePicker
              selected={dob}
              onChange={(date) => SetDob(date)}
              maxDate={yearsBack(3)}
              minDate={yearsBack(18)}
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              disabled={!isEdit}
            />
          </Grid>

          <Grid item md={4} xs={4}>
            {age && (
              <h3 className="secondaryText">
                {String(age).split(" ").indexOf("years") > -1
                  ? age
                  : `${age} years`}
              </h3>
            )}
          </Grid>

          <Grid item md={2} className={styles.textLabel} xs={12}>
            <label>Phone</label>
          </Grid>
          <Grid item md={10} className={styles.inputContainer} xs={12}>
            <input
              value={phone}
              onChange={(e) => SetPhone(e.target.value)}
              disabled={true}
            ></input>
          </Grid>
          <Grid item md={2} className={styles.textLabel} xs={12}>
            <label>City </label>
          </Grid>
          <Grid item md={10} className={styles.inputContainer} xs={12}>
            <input
              value={city}
              onChange={(e) => SetCity(e.target.value)}
              disabled={!isEdit}
            ></input>
          </Grid>
        </Grid>
      </Grid>
      {!isEdit && <div style={{ padding: 20 }}></div>}
      <div className={styles.bottomBtnGroup}>
        <div className={styles.fixedWrapper}>
          <Grid item md={12} className={`textCenter ${styles.updateBtn}`}>
            {isEdit && (
              <button
                className={isMobile ? "blackBtn" : "primaryBtn"}
                onClick={handleFormSubmit}
              >
                SAVE
              </button>
            )}
          </Grid>

          <div className={styles.hrline}></div>
          <Grid item md={12}>
            <p
              className={`${styles.datadelete} heading6 textCenter`}
              onClick={() => SetDeleteUserdataModal(true)}
            >
              DELETE ALL DATA AND ACCOUNT
            </p>
          </Grid>
        </div>
      </div>
      <div className={styles.modal}>
        <Modal
          className={modalClass.modal}
          open={deleteUserdataModal}
          hideBackdrop={true}
          onClose={handleClose}
          closeAfterTransition
          disableAutoFocus={true}
        >
          <div className={modalClass.paper}>
            <h1 className="heading2">
              Are you sure you want to delete your account
            </h1>
            <p className={`${styles.para}`}>All data will be lost forever</p>
            <button
              className="secondaryBtn exitBtn"
              onClick={handleDeleteUserData}
            >
              DELETE ACCOUNT
            </button>
            <button className="primaryBtn exitBtn" onClick={handleClose}>
              GO BACK - KEEP MY ACCOUNT
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userdetail: state.userdetail,
  };
};

export default connect(mapStateToProps)(withRouter(UserDetailCard));

import React, { useEffect } from "react";
import styles from "../../../assets/styles/userdetails.module.scss";
import UserScoreHeader from "./UserScoreHeader";
import UserScoreTable from "./UserScoreTable";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCookie } from "../../../shared_elements/cookie";
function UserScoreCard(props) {
  const { name, gender, phone } = props.userdetail;
  const uid = getCookie("uid");
  useEffect(() => {
    let isRedirected = true;
    if (isRedirected) {
      if (
        (name.length === 0 || gender.length === 0) &&
        uid.length !== 0 &&
        phone.length !== 0
      ) {
        props.history.push("/user-detail");
      } else if (!uid) {
        props.history.push("/sign-in");
      }
    }

    return () => (isRedirected = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.userScoreCard}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <UserScoreHeader />
        </Grid>
        <Grid item md={12} xs={12}>
          <UserScoreTable />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userdetail: state.userdetail,
  };
};

export default connect(mapStateToProps)(withRouter(UserScoreCard));

import React, { Component } from "react";
import SearchNearbyDoctors from "../components/SearchNearbyDoctors";
export default class SuggestNearbyDoctorPage extends Component {
  render() {
    return (
      <>
        <SearchNearbyDoctors />
      </>
    );
  }
}

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Slider,
  withStyles,
  Button,
} from "@material-ui/core";
import firebase from "../../../shared_elements/firebase/firebase";

import styles from "../../../assets/styles/userdetails.module.scss";
import Boy from "./icons/Boy";
import Girl from "./icons/Girl";
import { staticImages } from "../../../utils";

import { ADD_USERDETAIL } from "../../../shared_elements/redux/userdetails/actions";
import { getCookie } from "../../../shared_elements";

const CustomSlider = withStyles({
  root: {
    color: "#CCCCCC",
    borderRadius: "10px",
    padding: "13px 0",
  },
  thumb: {
    height: 58,
    width: 58,
    background: `url(${staticImages.sliderButton})`,
    marginTop: -26,
    marginLeft: -23,
    "&:focus, &:hover, &$active": {
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0)",
    },
    "& .bar": {
      height: 14,
      width: 1,
      backgroundColor: "#344880",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-0% + 15px)",
    textAlign: "start",
    whiteSpace: "nowrap",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 3,
    borderRadius: "10px",
  },
  rail: {
    color: "#CCCCCC",
    opacity: 1,
    borderRadius: "10px",
    height: 3,
  },
})(Slider);

const marks = [
  {
    value: 3,
    label: "3 years",
  },
  {
    value: 10.5,
    label: "Drag to set your age",
  },
  {
    value: 18,
    label: "18 years",
  },
];

function UserDetail(props) {
  const [name, SetName] = useState("");
  const [gender, SetGender] = useState("");
  const [age, SetAge] = useState(3);
  const [disableSubmit, SetDisableSubmit] = useState(true);

  useEffect(() => {
    if (name.length !== 0 && gender && age > 2) {
      SetDisableSubmit(false);
    } else {
      SetDisableSubmit(true);
    }
  }, [name, age, gender]);

  useEffect(() => {
    const { name, gender } = props.userdetail;
    if (name.length !== 0 && gender.length !== 0) {
      props.history.push("/assessment");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userdetail]);

  const handleSubmit = (e) => {
    const uid = getCookie("uid");
    e.preventDefault();
    const data = {
      name,
      gender,
      age,
    };

    if (uid) {
      const userRef = firebase.firestore().collection("users").doc(uid);
      const formData = { name, age, gender };
      props.dispatch({ type: ADD_USERDETAIL, payload: data });
      if (uid && name.length !== 0 && age > 2 && gender.length !== 0) {
        userRef.get().then((docSnap) => {
          if (docSnap.exists) {
            userRef.update(formData);
            props.history.push("/user-profile");
          } else {
            userRef
              .set(formData)
              .then(() => {
                props.history.push("/user-profile");
              })
              .catch(() => {
                // console.log("error in saving user data");
                props.history.push("/assessments");
              });
          }
        });
      }
    } else {
      props.dispatch({ type: ADD_USERDETAIL, payload: data });
      // console.log("uid doesnot exist to push data");
    }
    props.history.push("/assessment");
  };

  return (
    <Grid container className={styles.userInitialDetails}>
      <Grid item md={12} sm={12}>
        <h2 className="heading2">Before we begin, 3 quick questions</h2>
      </Grid>
      <Grid item className={styles.userName} md={6} xs={12}>
        <p className="parargraph">Tell us your full name</p>
        <input
          id="name"
          variant="outlined"
          value={name}
          onChange={(e) =>
            SetName(
              e.target.value.length === 1
                ? e.target.value.toUpperCase()
                : e.target.value
            )
          }
        />
      </Grid>
      <Grid item className={styles.userGender} md={12} sm={12} xs={12}>
        <p className="parargraph">Your gender</p>
        <RadioGroup
          name="gender"
          id="gender"
          value={gender}
          className={styles.radioGrp}
          row={true}
          onChange={(e) => {
            SetGender(e.target.value);
          }}
        >
          <FormControlLabel
            value="male"
            control={
              <Radio
                className={styles.radioBtn}
                checkedIcon={
                  <img
                    src={staticImages.checked}
                    alt="checked"
                    className={styles.checkedIcon}
                  ></img>
                }
                icon={<div className={styles.radioEmpty}></div>}
              />
            }
            className={
              gender === "male"
                ? styles.radioButtonFormClicked
                : styles.radioButtonForm
            }
            label={
              <span className={`${styles.icons}`}>
                <Boy color={gender === "male" ? "white" : "black"} />
                <p className={`paragraph ${styles.labelIcon}`}>Boy</p>
              </span>
            }
          />
          <FormControlLabel
            value="female"
            control={
              <Radio
                className={styles.radioBtn}
                checkedIcon={
                  <img
                    src={staticImages.checked}
                    alt="checked"
                    className={styles.checkedIcon}
                  ></img>
                }
                icon={<div className={styles.radioEmpty}></div>}
              />
            }
            className={
              gender === "female"
                ? styles.radioButtonFormClicked
                : styles.radioButtonForm
            }
            label={
              <span className={styles.icons}>
                <Girl color={gender === "female" ? "white" : "black"} />
                <p className={`paragraph ${styles.labelIcon}`}>Girl</p>
              </span>
            }
          />
        </RadioGroup>
      </Grid>
      <Grid item md={12} className={styles.slider} sm={12} xs={12}>
        <p className="parargraph">Your age</p>
        <span className={styles.customSlider}>
          <CustomSlider
            valueLabelDisplay="auto"
            defaultValue={3}
            min={3}
            value={age}
            onChange={(e, value) => SetAge(value)}
            max={18}
            marks={marks}
            step={0.5}
            getAriaValueText={() => {
              return `${age}`;
            }}
            valueLabelFormat={() => {
              return `${age} Years`;
            }}
          />
        </span>
      </Grid>
      <Grid item className={styles.submitBtn} md={12} sm={12} xs={12}>
        <Button
          className="primaryBtn"
          disabled={disableSubmit}
          onClick={handleSubmit}
        >
          CONTINUE
        </Button>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    userdetail: state.userdetail,
  };
};

export default connect(mapStateToProps)(UserDetail);

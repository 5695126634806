import React from "react";
import { Grid, Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { BrowserView } from "./";
import { Content } from "./multiLanguage/containers/language";

function AboutMyopal(props) {
  return (
    <div className={`aboutContainer ${props.visible ? "" : "disableCard"}`}>
      <Grid container>
        <Grid item md={12} sm={12} className="aboutHeader">
          <Link to="/about" className="link">
            <h2 className="heading2">
              <Content path="routes.home.about_card.header" />
            </h2>
            <h3 className="heading6">
              <Content path="routes.home.about_card.subinfo" />
            </h3>
            <h4 className="heading6">
              <Content path="routes.home.about_card.subheader" />
            </h4>
          </Link>
        </Grid>
        <BrowserView>
          <Grid item md={12} sm={12} className="bottomGrp">
            <Link to="/about" className="link">
              <Button
                className="smallLabel btnMore"
                endIcon={<ArrowForwardIosIcon className="arrowIcon" />}
              >
                <Content path="routes.home.about_card.button" />
              </Button>
            </Link>
          </Grid>
        </BrowserView>
      </Grid>
    </div>
  );
}

AboutMyopal.propTypes = {
  visible: PropTypes.bool,
};

AboutMyopal.defaultProps = {
  visible: true,
};

export default AboutMyopal;

import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Modal,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import { staticImages } from "../utils";
import "../assets/styles/app.scss";

import { CLEAR_RESPONSE } from "./redux/responses/actions";
import { RESET_USERDETAIL } from "./redux/userdetails/actions";
import { RESET_TIMER } from "./redux/timer/actions";
import Timer from "./Timer";
import { BrowserView, MobileView, removeCookie, getCookie } from "./";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: "190px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    disableScrollLock={true}
    keepMounted
    variant="menu"
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    opacity: 0.98,
  },
  root: {
    backgroundColor: "white",
  },
  menuModal: {
    display: "flex",
    padding: "70px 0 0 0",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "white",
  },
  menuPaper: {
    position: "absolute",
    "&:focus": {
      outline: "none",
    },
  },
  footer: {
    position: "fixed",
    left: "0px",
    bottom: "0px",
  },
  vrline: {
    border: "0.5px solid #dddddd",
    opacity: 0.8,
    width: "100vw",
  },
  route: {
    padding: "0px 17px 0px 17px",
  },
  copyright: {
    textDecoration: "none",
    color: "#777777",
  },
  crContainer: {
    padding: "0 0 10px 20px",
  },
  credit: {
    padding: "10px 0 0 20px",
  },
  btn: {
    paddingTop: "20px",
  },
}));

const styleForModal = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    zIndex: "3000",
  },
  root: {
    backgroundColor: "white",
  },
}));

function NavBar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [showUserProfile, SetShowUserProfile] = useState(false);
  const [showLoginRouter, setShowLoginRoute] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openSignOutModal, setOpenSignOutModal] = React.useState(false);
  const uid = getCookie("uid");
  const { pathname } = props.location;
  const handleOpenMenu = (e) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRouteClose = () => {
    setOpenMenu(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExit = () => {
    setOpen(false);
    props.dispatch({ type: CLEAR_RESPONSE });
    props.history.push("/");
  };

  const handleSignOut = () => {
    removeCookie("uid");
    removeCookie("cookieExpiry");
    SetShowUserProfile(false);
    setShowLoginRoute(false);
    setOpenMenu(false);
    setOpenSignOutModal(false);
    props.history.push("/");
    props.dispatch({ type: CLEAR_RESPONSE });
    props.dispatch({ type: RESET_USERDETAIL });
    props.dispatch({ type: RESET_TIMER });
  };

  useEffect(() => {
    if (
      uid &&
      props.userdetail &&
      props.userdetail.name &&
      props.userdetail.name.length !== 0 &&
      pathname !== "/user-detail"
    ) {
      SetShowUserProfile(true);
    }
  }, [props.userdetail, uid, pathname]);

  const { name, imgUrl, lastAthlete } = props.userdetail;
  const modalClass = styleForModal();
  return (
    <header className="navBar">
      <div className={classes.root}>
        <Modal
          open={open}
          hideBackdrop={true}
          onClose={handleClose}
          closeAfterTransition
          disableAutoFocus={true}
          className={classes.modal}
        >
          <div className={classes.paper}>
            <h2 className="heading2">
              Are you sure you want to exit this test?
            </h2>
            <p className="paragraph">
              All details entered in this session so far won’t be saved.
            </p>
            <div className={classes.btn}>
              <button className="blackBtn dialogBtn" onClick={handleExit}>
                EXIT
              </button>
            </div>

            <button className="primaryBtn dialogBtn" onClick={handleClose}>
              RETURN
            </button>
          </div>
        </Modal>
        {/* Web view */}
        <BrowserView>
          <Container maxWidth="md">
            <Grid
              container
              justify="flex-start"
              className="navContainer flexCentered"
            >
              <Grid
                item
                md={6}
                sm={6}
                xs={6}
                className={`${props.visible ? "" : "disableCard"}`}
              >
                <Link to="/">
                  <img src={staticImages.logo} alt="logo" className="logo" />
                </Link>
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={6}
                spacing={1}
                container
                justify="flex-end"
                className="flexCentered"
              >
                {pathname === "/assessment" ? (
                  <>
                    <Grid item>
                      <button
                        className={`${
                          !open ? "blackBtn" : "blackBtn disableCard"
                        }`}
                        onClick={handleOpen}
                      >
                        EXIT TEST
                      </button>
                    </Grid>

                    <Grid item>
                      <Timer />
                    </Grid>
                  </>
                ) : (
                  <>
                    {!showUserProfile &&
                      pathname !== "/sign-in" &&
                      pathname !== "/user-detail" && (
                        <>
                          <Grid item className="flexCentered">
                            <Link className="router" to="/sign-in">
                              <img
                                src={staticImages.profileImg}
                                alt="logo"
                                className="userIcon"
                              />
                            </Link>
                          </Grid>
                          <Grid item>
                            <Link className="router" to="/sign-in">
                              <p className="secondaryText">Login/Register</p>
                            </Link>
                          </Grid>
                        </>
                      )}
                  </>
                )}
                {pathname !== "/assessment" &&
                  pathname !== "/page404" &&
                  showUserProfile && (
                    <>
                      <Grid item>
                        <Link className="router" to="/user-profile">
                          {imgUrl ? (
                            <img
                              src={imgUrl}
                              alt="logo"
                              className="imgAvatar"
                            />
                          ) : (
                            <>
                              <img
                                src={staticImages.profileImg}
                                alt="logo"
                                className="userIcon"
                              />
                            </>
                          )}
                        </Link>
                      </Grid>

                      <Grid item>
                        {(name || lastAthlete) && uid ? (
                          <>
                            <Link className="router" to="/user-profile">
                              <p className="secondaryText flexCentered">
                                {name}
                              </p>
                              <p className="smallLabel flexCentered">
                                {lastAthlete}
                              </p>
                            </Link>
                          </>
                        ) : (
                          uid && (
                            <Link className="router" to="/sign-in">
                              <p className="secondaryText">Login/Register</p>
                            </Link>
                          )
                        )}
                      </Grid>

                      {(name || lastAthlete) && uid && (
                        <Grid item>
                          <IconButton
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={handleOpenMenu}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                          >
                            <MenuItem
                              onClick={() => {
                                setOpenSignOutModal(true);
                              }}
                              disableGutters={true}
                            >
                              Sign-out
                            </MenuItem>
                          </StyledMenu>
                        </Grid>
                      )}
                    </>
                  )}
              </Grid>
            </Grid>
          </Container>
        </BrowserView>
        {/* Mobile view */}
        <MobileView>
          <Grid container className="mobileNavContainer">
            <Grid
              item
              xs={5}
              className={`${props.visible ? "flexCentered" : "disableCard"}`}
            >
              <Link to="/">
                <img src={staticImages.logo} alt="logo" className="logo" />
              </Link>
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              xs={7}
              className="flexCentered"
              spacing={1}
            >
              {pathname === "/assessment" ? (
                <>
                  <Grid
                    item
                    xs={12}
                    className={"timer flexCentered"}
                    direction="row"
                    container
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Timer />
                    </Grid>
                    <Grid item>
                      <button
                        className={`${
                          !open ? "blackBtn" : "blackBtn disableCard"
                        }`}
                        onClick={handleOpen}
                      >
                        EXIT TEST
                      </button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  {!showUserProfile && !(pathname === "/sign-in") && (
                    <>
                      <Grid item>
                        <img
                          src={staticImages.profileImg}
                          alt="logo"
                          className="profileIcon"
                          onClick={() => {
                            setOpenMenu(true);
                            setShowLoginRoute(true);
                          }}
                        />

                        {!openMenu && (
                          <MenuIcon
                            className="menuIcon"
                            onClick={() => setOpenMenu(true)}
                          />
                        )}
                        {openMenu && (
                          <CloseIcon
                            className="menuIcon"
                            onClick={() => {
                              setOpenMenu(false);
                              setShowLoginRoute(false);
                            }}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}
              {pathname !== "/assessment" &&
                pathname !== "/page404" &&
                showUserProfile && (
                  <>
                    <Grid item>
                      <Link className="router" to="/user-profile">
                        {imgUrl ? (
                          <img src={imgUrl} alt="logo" className="imgAvatar" />
                        ) : (
                          <>
                            <img
                              src={staticImages.profileImg}
                              alt="logo"
                              className="profileIcon"
                            />
                          </>
                        )}
                      </Link>
                      {!openMenu && (
                        <MenuIcon
                          className="menuIcon"
                          onClick={() => setOpenMenu(true)}
                        />
                      )}
                      {openMenu && (
                        <CloseIcon
                          className="menuIcon"
                          onClick={() => {
                            setOpenMenu(false);
                            setShowLoginRoute(false);
                          }}
                        />
                      )}
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
          <Modal
            open={openMenu}
            hideBackdrop={true}
            onClose={handleRouteClose}
            closeAfterTransition
            disableAutoFocus={true}
            className={classes.menuModal}
          >
            <div className={classes.menuPaper}>
              <Grid container>
                {showLoginRouter && (
                  <>
                    <Grid item xs={12} className={classes.route}>
                      <Link className="router" to="/sign-in">
                        <h4 className="titleLink"> Login/Register</h4>{" "}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.vrline} />
                    </Grid>
                  </>
                )}
                {showUserProfile && (
                  <>
                    <Grid item xs={12} className={classes.route}>
                      <Link className="router" to="/user-profile">
                        <h4 className="titleLink"> {name}'s Profile</h4>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.vrline} />
                    </Grid>
                    <Grid item xs={12} className={classes.route}>
                      <Link className="router" to="/user-score">
                        <h4 className="titleLink"> {name}'s Reports</h4>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.vrline} />
                    </Grid>
                    <Grid item xs={12} className={classes.route}>
                      <h4
                        className="titleLink"
                        onClick={() => {
                          setOpenSignOutModal(true);
                        }}
                        style={{ color: "#C91C1C" }}
                      >
                        Sign-out
                      </h4>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.vrline} />
                    </Grid>
                  </>
                )}
                {!showLoginRouter && !showUserProfile && (
                  <>
                    <Grid item xs={12} className={classes.route}>
                      <Link className="router" to="/about">
                        <h4 className="titleLink"> What is Myopal ?</h4>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.vrline} />
                    </Grid>
                    <Grid item xs={12} className={classes.route}>
                      <Link className="router" to="/faq">
                        <h4 className="titleLink">
                          Frequently asked questions
                        </h4>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.vrline} />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} className={classes.footer}>
                  <h4 className={`navBarFont ${classes.crContainer}`}>
                    <Link to="/terms-&-condition" className={classes.copyright}>
                      {" "}
                      Contact · Terms & Conditions{" "}
                    </Link>
                    ·{" "}
                    <Link to="/privacy-policy" className={classes.copyright}>
                      Privacy Policy
                    </Link>
                  </h4>
                  <div className={classes.vrline} />
                  <h4 className={`navBarFont ${classes.credit}`}>
                    © 2020 Myopal
                  </h4>
                  <h4 className={`navBarFont ${classes.crContainer}`}>
                    Design and Developed by{" "}
                    <a
                      href="https://www.designstring.com"
                      className="anchorTag"
                    >
                      DesignString
                    </a>
                  </h4>
                </Grid>
              </Grid>
            </div>
          </Modal>
        </MobileView>
      </div>
      <div>
        <Modal
          className={modalClass.modal}
          open={openSignOutModal}
          hideBackdrop={true}
          onClose={() => {
            setOpenSignOutModal(false);
          }}
          closeAfterTransition
          disableAutoFocus={true}
        >
          <div className={modalClass.paper}>
            <h1 className="heading2">Signout?</h1>
            <p className="paragraph">
              Are you sure you want to signout from your myopal account?
            </p>
            <button className="secondaryBtn exitBtn" onClick={handleSignOut}>
              SIGNOUT
            </button>
            <button
              className="primaryBtn exitBtn"
              onClick={() => {
                setOpenSignOutModal(false);
              }}
            >
              CANCEL
            </button>
          </div>
        </Modal>
      </div>
    </header>
  );
}

NavBar.propTypes = {
  visible: PropTypes.bool,
};

NavBar.defaultProps = {
  visible: true,
};

const mapStateToProps = (state) => {
  return {
    userdetail: state.userdetail,
  };
};

export default connect(mapStateToProps)(withRouter(NavBar));

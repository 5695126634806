import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import styles from "../../../assets/styles/userdetails.module.scss";
import moment from "moment";
import { connect } from "react-redux";

function UserScoreHeader(props) {
  const isMobile = !useMediaQuery("(min-width:600px)");
  const { lastAthlete } = props.userdetail;
  return (
    <div className={`${styles.userScoreHeader}`}>
      <Grid container>
        <Grid item md={8} xs={6}>
          <p className={`${styles.userType} heading5 textLeft`}>
            {isMobile ? "Reports" : lastAthlete ? `You are ${lastAthlete}` : ""}
          </p>
        </Grid>
        <Grid item md={4} className={styles.dateContainer} xs={6}>
          <p className={`smallLabel ${styles.date}`}>
            {moment().format("dddd , ll")}
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userdetail: state.userdetail,
  };
};

export default connect(mapStateToProps)(UserScoreHeader);

import React from "react";
import WelcomeCard from "../components/WelcomeCard";
import { Content } from "../../../shared_elements/multiLanguage/containers/language";

export default function HomeContainer() {
  return (
    <WelcomeCard
      title={<Content path="routes.home.welcome_card.header" />}
      content={<Content path="routes.home.welcome_card.subheader" />}
      medicalWarning={
        <Content path="routes.home.welcome_card.medical_warning" />
      }
      btnName={<Content path="routes.home.welcome_card.button" />}
      link={<Content path="routes.home.welcome_card.link" />}
      downloadTest={<Content path="routes.home.welcome_card.downloadTest" />}
    />
  );
}

import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import styles from "../../../assets/styles/assessment.module.scss";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as Checked } from "./icons/checked.svg";

export default function AssessmentOptions(props) {
  const [checked, SetChecked] = useState(false);

  return (
    <div className={styles.options} onClick={() => SetChecked(!checked)}>
      <Grid container>
        <Grid item md={1} sm={3} xs={1} className={styles.assessmentOptions}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.checked}
                icon={<div className={styles.unchecked} />}
                checkedIcon={<Checked className={styles.checked} />}
                name="checked"
              />
            }
          />
        </Grid>
        <Grid item md={8} sm={6} xs={8} className="flexCentered textLeft">
          <p className={`${styles.optionDesc} heading5`}>{props.question}</p>
        </Grid>
        <Grid
          item
          md={2}
          sm={3}
          container
          justify="flex-end"
          xs={3}
          className="flexCentered"
        >
          <p className={`${styles.optionPoint} smallLabel`}>
            {props.point} POINT
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

const baseurl =
  "https://firebasestorage.googleapis.com/v0/b/project---myopal.appspot.com/o/site%2Fassets%2F";

export const staticImages = {
  boy: baseurl.concat(
    "boy.svg?alt=media&token=2119b9bf-770d-4c95-8b89-96505eae5733"
  ),
  bluePrint: baseurl.concat(
    "bluePrint.svg?alt=media&token=a717b8a8-022c-4f78-9488-6951ace67c37"
  ),
  blueDownload: baseurl.concat(
    "blueDownload.svg?alt=media&token=3cb1deaa-4de7-4c76-bf63-1bed0b33745c"
  ),
  girl: baseurl.concat(
    "girl.svg?alt=media&token=c801fd40-e08e-48e5-8841-40211c215ab2"
  ),
  timer3: baseurl.concat(
    "timer3.svg?alt=media&token=65630f34-efc1-47f3-97d2-10e7defb9c98"
  ),
  particals: baseurl.concat(
    "particals.svg?alt=media&token=99cb0db9-1113-4fda-bd24-6e31d12c33e1"
  ),
  cake: baseurl.concat(
    "cake.svg?alt=media&token=736390e9-4a71-408a-9d23-48232a659998"
  ),
  calendar: baseurl.concat(
    "calendar.svg?alt=media&token=3b69ef74-6229-479d-b832-6ddf9319adae"
  ),
  checked: baseurl.concat(
    "checked.svg?alt=media&token=60579f66-1656-4ea5-b1ad-6f42688b5648"
  ),
  childrenSmall: baseurl.concat(
    "children.jpg?alt=media&token=1712d868-c688-4dff-85a9-d90dae3a6600"
  ),
  childrenWide: baseurl.concat(
    "childrenabout.jpg?alt=media&token=f96e19f6-cab5-406c-9a7c-49925e85f76f"
  ),
  clap: baseurl.concat(
    "clap.svg?alt=media&token=b57292ae-5d65-46d0-b065-b50582592004"
  ),
  coachMarker: baseurl.concat(
    "coachMarker.png?alt=media&token=0b94d846-7913-4915-afec-b44438608346"
  ),
  mobileCoachMarker: baseurl.concat(
    "mobileCoachMarker.png?alt=media&token=fc9530b6-b232-4910-aa25-09ca472d9cc4"
  ),
  doctorBlue: baseurl.concat(
    "doctorBlue.svg?alt=media&token=ba0c0ca8-5896-4107-a9dd-a0f61f96c50b"
  ),
  emailBlue: baseurl.concat(
    "emailBlue.svg?alt=media&token=ab81f727-4f67-4d0a-b004-2dc8fcf2249a"
  ),
  genderIcon: baseurl.concat(
    "gender.svg?alt=media&token=ee1824a5-3220-4642-81b9-84ec71f014da"
  ),
  faqGroup: baseurl.concat(
    "groupfaq.svg?alt=media&token=a3939d31-eae1-4bd9-8a34-23c2465ea636"
  ),
  leftBcg: baseurl.concat(
    "leftbcg.svg?alt=media&token=2ed42860-691a-4e73-88c6-a6d0272ddfb1"
  ),
  logo: baseurl.concat(
    "logo.svg?alt=media&token=b3099550-97b5-4c45-8265-011ccac53bb2"
  ),
  logoBlack: baseurl.concat(
    "logoBlack.svg?alt=media&token=969e7f62-1579-49c3-b8c0-19914760dc6a"
  ),
  mailIconBlack: baseurl.concat(
    "mailBlack.svg?alt=media&token=0301a203-7986-41e4-aaf8-4f7e2e34b7f8"
  ),
  mailIconGray: baseurl.concat(
    "mailgray.svg?alt=media&token=4bfdca44-7498-441e-b16f-fbe67ba10312"
  ),
  marker: baseurl.concat(
    "marker.svg?alt=media&token=2db2dd3a-a2af-420d-bb78-2f491e15b987"
  ),
  medical: baseurl.concat(
    "medical.svg?alt=media&token=666abbfb-7378-4bc3-a11b-aba1a98d61ca"
  ),

  mobile: baseurl.concat(
    "mobile.svg?alt=media&token=8b0bf989-e9b6-4259-911e-88ffc3fed3d4"
  ),
  profileImg: baseurl.concat(
    "profileImg.svg?alt=media&token=f6a56eb6-f213-4368-8e04-98c959af9541"
  ),
  pdfIcon: baseurl.concat(
    "pdfIcon.svg?alt=media&token=2968a13d-7e56-485f-9270-0310398553ea"
  ),
  rightBcg: baseurl.concat(
    "rightbcg.svg?alt=media&token=b0dbcfa3-f227-4769-80cd-420a0ab080ce"
  ),
  serverDown: baseurl.concat(
    "serverDown.png?alt=media&token=81197032-027d-45f9-8b41-e64b12bd47ec"
  ),
  stopWatch: baseurl.concat(
    "stopWatch.svg?alt=media&token=e0ed2f1c-c2f0-4433-9719-b1a6900683af"
  ),
  someThingWrong: baseurl.concat(
    "someThingWrong.png?alt=media&token=1579fb0f-7a2d-447d-a027-06b77bd2b0cc"
  ),
  sliderButton: baseurl.concat(
    "sliderButton.svg?alt=media&token=daccc8b4-0df1-49c0-b142-15f39d721543"
  ),
  unchecked: baseurl.concat(
    "unchecked.svg?alt=media&token=d0863589-c158-42a8-9323-d387fa91b25e"
  ),
  userProfileBlue: baseurl.concat(
    "userProfileBlue.svg?alt=media&token=558859e4-69c0-473b-acd7-c78f1181ceae"
  ),
  userProfileGrey: baseurl.concat(
    "userprofilegray.svg?alt=media&token=29ae5b20-d34c-4221-b734-451b3039679d"
  ),
};

export function athete(score = 0, gender = "male") {
  if (typeof score !== "number") score = 0;
  const maleAthletes = [
    "Wanye Rooney",
    "David Beckham",
    "Ronaldo",
    "Messie",
    "Roger Fedrer",
    "Usain Bolt",
    "Micheal Jordan",
    "Malinga",
    "Sachin Tendulkar",
    "Milka Singh",
    "Virat Kohli",
    "MS Dhoni",
  ];

  const femaleAthletes = [
    "Serena Williams",
    "Maria Sharapova",
    "Mary Kom",
    "Saina Nehwal",
    "Alex Morgan",
    "Ashleigh Barty",
    "Mithali Raj",
    "Smriti Mandhana",
    "Deepika Kumari",
    "Naomi Osaka",
    "Simona Halep",
    "Sofia Kenin",
  ];

  if (gender === "male") {
    switch (true) {
      case score < 4:
        return maleAthletes[0];
      case score > 4 && score < 8:
        return maleAthletes[1];
      case score > 8 && score < 12:
        return maleAthletes[2];
      case score > 12 && score < 16:
        return maleAthletes[3];
      case score > 16 && score < 20:
        return maleAthletes[4];
      case score > 20 && score < 24:
        return maleAthletes[5];
      case score > 24 && score < 28:
        return maleAthletes[6];
      case score > 28 && score < 32:
        return maleAthletes[7];
      case score > 36 && score < 40:
        return maleAthletes[8];
      case score > 40 && score < 44:
        return maleAthletes[9];
      case score > 44 && score < 48:
        return maleAthletes[10];
      default:
        return maleAthletes[11];
    }
  } else if (gender === "female") {
    switch (true) {
      case score < 4:
        return femaleAthletes[0];
      case score > 4 && score < 8:
        return femaleAthletes[1];
      case score > 8 && score < 12:
        return femaleAthletes[2];
      case score > 12 && score < 16:
        return femaleAthletes[3];
      case score > 16 && score < 20:
        return femaleAthletes[4];
      case score > 20 && score < 24:
        return femaleAthletes[5];
      case score > 24 && score < 28:
        return femaleAthletes[6];
      case score > 28 && score < 32:
        return femaleAthletes[7];
      case score > 36 && score < 40:
        return femaleAthletes[8];
      case score > 40 && score < 44:
        return femaleAthletes[9];
      case score > 44 && score < 48:
        return femaleAthletes[10];
      default:
        return femaleAthletes[11];
    }
  } else {
    return "";
  }
}

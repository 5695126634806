import * as actions from "./actions";
// initial State = [{questionName:"",questionNo:"1",userResponse:"option",userTimeSpent:"50 sec",points:"1"}]
const initialState = [];
export default function (state = initialState, action) {
  switch (action.type) {
    case actions.ADD_RESPONSE:
      return [...state, action.payload];

    case actions.REMOVE_RESPONSE:
      return state.filter((data) => data.questionNo !== action.payload);

    case actions.EDIT_RESPONSE:
      return state.map((data) => {
        return data.questionNo === action.payload.questionNo
          ? Object.assign({}, data, action.payload.data)
          : Object.assign({}, data);
      });

    case actions.FETCH_RESPONSE:
      return [...state];
    case actions.CLEAR_RESPONSE:
      return initialState;

    default:
      return state;
  }
}

import React from "react";
import { staticImages } from "../utils";
export default function Background() {
  return (
    <>
      <img src={staticImages.leftBcg} className="leftBcg" alt="leftbcg"></img>
      <img
        src={staticImages.rightBcg}
        className="rightBcg"
        alt="rightbcg"
      ></img>
    </>
  );
}

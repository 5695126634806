import React, { useState } from "react";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import styles from "../../../assets/styles/signin.module.scss";
import firebase from "../../../shared_elements/firebase/firebase";
import { setCookie, getCookie } from "../../../shared_elements/cookie";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ADD_USERDETAIL } from "../../../shared_elements/redux/userdetails/actions";
import moment from "moment";
import { athete } from "../../../utils";
import { MobileView, BrowserView } from "../../../shared_elements";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PhoneVerification(props) {
  const [phone, SetPhone] = useState("91");
  // const [phoneCode] = useState("+91");
  const [continueBtn, SetContinueBtn] = useState(false);
  const [otp, SetOtp] = useState("");
  const [verificationId, SetVerificationId] = useState("");
  const [sendOtp, SetSendOtp] = useState(false);
  const [invalid, SetInvalid] = useState(false);
  const [invalidOtp, SetInvalidOtp] = useState(false);
  const [resendOtp, SetResendOtp] = useState(false);

  const isValidNumber = (num) => {
    const reg = /[0-9]{0,14}$/g;
    return reg.test(num);
  };

  const handleVerification = () => {
    SetSendOtp(true);
    if (phone.length > 5 && phone.length < 15) {
      var appVerifier = new firebase.auth.RecaptchaVerifier(
        "phone-sign-in-recaptcha",
        {
          size: "invisible",
          callback: function (response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
          "expired-callback": function () {
            //incase captacha verification fails or timeout
          },
        }
      );
      let phoneNumber = "+" + phone;

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function (e) {
          SetContinueBtn(true);
          SetVerificationId(e.verificationId);
          appVerifier.clear();
          return;
        });

      props.dispatch({
        type: ADD_USERDETAIL,
        payload: { phone: "+" + phone },
      });
    } else {
      SetInvalid(true);
      SetSendOtp(false);
    }
  };

  const handleOtp = () => {
    const isUID = getCookie("uid");

    if (isUID) {
      props.history.push("/user-profile");
    } else {
      var credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        otp
      );
      firebase
        .auth()
        .signInWithCredential(credential)
        .then((res) => {
          const { uid } = res.user;

          setCookie("uid", uid, 2);
          setCookie("cookieExpiry", String(moment().format()), 2);

          if (uid) {
            props.dispatch({
              type: ADD_USERDETAIL,
              payload: { phone: "+" + phone },
            });

            let {
              lastDateAsses,
              lastDayAsses,
              lastTimeAsses,
              name,
              gender,
              age,
            } = props.userdetail;

            if (props.responses && props.responses.length !== 0 && uid) {
              let athlete = athete(Number(props.points), gender);
              athlete = athlete ? athlete : "";
              lastDayAsses = lastDayAsses ? lastDayAsses : "";
              lastDateAsses = lastDateAsses ? lastDateAsses : "";
              lastTimeAsses = lastTimeAsses ? lastTimeAsses : "";
              const formData = {
                response: props.responses,
                totalPoints: props.points,
                atlete: athlete,
                day: lastDayAsses,
                date: lastDateAsses,
                time: lastTimeAsses,
              };

              firebase
                .firestore()
                .collection("users")
                .doc(uid)
                .get()
                .then((docSnap) => {
                  if (docSnap.exists) {
                    firebase
                      .firestore()
                      .collection("users")
                      .doc(uid)
                      .collection("assessments")
                      .add(formData)
                      .then(() => {
                        console.log("added existing");
                      });
                  } else {
                    firebase
                      .firestore()
                      .collection("users")
                      .doc(uid)
                      .set({ date: new Date() })
                      .then(() => {
                        firebase
                          .firestore()
                          .collection("users")
                          .doc(uid)
                          .collection("assessments")
                          .add(formData)
                          .then(() => {
                            console.log("added new");
                          });
                      });
                  }
                });

              props.dispatch({
                type: ADD_USERDETAIL,
                payload: {
                  lastAthlete: athlete,
                },
              });
            }
            let athlete = athete(Number(props.points), gender);
            const formData = {
              name,
              gender,
              phone: "+" + phone,
              lastAthlete: athlete,
              age,
            };

            const userRef = firebase.firestore().collection("users").doc(uid);
            if (uid) {
              userRef.get().then((docSnap) => {
                if (docSnap.exists) {
                  firebase
                    .firestore()
                    .collection("users")
                    .doc(uid)
                    .get()
                    .then((doc) => {
                      const userData = doc.data();
                      if (userData) {
                        let {
                          name,
                          imgUrl,
                          city,
                          dob,
                          lastAthlete,
                          age,
                          gender,
                        } = userData;
                        name = name ? name : "";
                        imgUrl = imgUrl ? imgUrl : "";
                        city = city ? city : "";
                        dob = dob ? dob : "";
                        lastAthlete = lastAthlete ? lastAthlete : "";
                        age = age ? age : "";
                        gender = gender ? gender : "";
                        if (name && gender && age) {
                          props.dispatch({
                            type: ADD_USERDETAIL,
                            payload: {
                              name,
                              phone,
                              city,
                              dob,
                              lastAthlete,
                              age,
                              imgUrl,
                              gender,
                            },
                          });
                        }

                        if (name && age && gender) {
                          props.history.push("/user-profile");
                        } else {
                          props.history.push("/user-detail");
                        }
                      }
                    })
                    .catch((err) => {
                      // console.log("data doesnot exist", err);
                    });
                } else {
                  userRef
                    .set(formData)
                    .then(() => {
                      // console.log(formData, "user phone formData");
                      if (props.userdetail.gender && props.userdetail.name) {
                        props.history.push("/user-profile");
                      } else {
                        props.history.push("/user-detail");
                      }
                    })
                    .catch(() => {
                      // console.log("error in saving user data");
                      props.history.push("/assessment");
                    });
                }
              });
            } else {
              props.history.push("/sign-in");
              // console.log(
              //   "user is not registered or login , as uid is not available"
              // );
              SetContinueBtn(false);
            }
          }
        })
        .catch((err) => {
          SetInvalidOtp(true);
          // console.log(
          //   err,
          //   "error while logging in redirecting to sigin page again"
          // );
        });
    }
  };

  const handleResend = () => {
    SetResendOtp(true);
    SetPhone("");
    var appVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          //after captacha verification
        },
        "expired-callback": function () {
          //incase captacha verification fails or timeout
        },
      }
    );
    let phoneNumber = "+" + phone;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (e) {
        SetContinueBtn(true);
        SetVerificationId(e.verificationId);
        return;
      });
  };

  const handlePhoneNumber = (phoneNo) => {
    if (phoneNo.length < 14 || phoneNo.length === 0) {
      SetPhone(phoneNo);
    }
  };

  const handleOnPhoneBlur = () => {
    if (isValidNumber(phone)) {
      SetInvalid(false);
    } else {
      SetInvalid(true);
    }
  };

  const handleOtpChange = (otp) => {
    if ((otp.length < 7 && isValidNumber(otp)) || otp.length === 0) {
      SetOtp(otp);
    }
  };
  return (
    <>
      <BrowserView>
        <Grid container>
          {!continueBtn ? (
            <React.Fragment>
              <Grid item md={12} xs={12}>
                <h2 className="heading">Create an account?</h2>
              </Grid>
              <Grid item md={12} xs={12}>
                <p className="paragraph">Enter your mobile number</p>
                {/* <input type="text" value={phoneCode} id="phonecode" disabled />
                <input
                  type="tel"
                  id="phone"
                  required
                  value={phone}
                  title="A valid phone number is required"
                  onChange={(e) => {
                    handlePhoneNumber(e.target.value);
                  }}
                  onBlur={handleOnPhoneBlur}
                /> */}
                <div className="phoneInput">
                  <PhoneInput
                    value={phone}
                    preferredCountries={["in", "us", "gb"]}
                    onChange={(phone) => handlePhoneNumber(phone)}
                    onBlur={handleOnPhoneBlur}
                    autoFormat={true}
                    placeholder=""
                  />
                </div>

                {invalid && (
                  <p className="errorLabel">Enter a valid phone number </p>
                )}
              </Grid>
              <Grid item md={12} xs={12} className={styles.btnContainer}>
                <div id="phone-sign-in-recaptcha"></div>

                <Button
                  className={`blackBtn ${styles.docBtn}`}
                  onClick={handleVerification}
                  disabled={sendOtp}
                >
                  {!sendOtp ? (
                    "Continue"
                  ) : (
                    <CircularProgress
                      className={styles.circularProgress}
                      size={22}
                    />
                  )}
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                <p className={`${styles.otpInfo} paragraph`}>
                  We’ll send you an OTP on the mobile number to Register on
                  Myopal
                </p>
              </Grid>
              <Grid item md={12} className={styles.accountInfo}>
                <p className="paragraph">
                  By creating a Myopal account, you will be able to store your
                  tests scores. All your data is encrypted and stored. You can
                  delete all data and account anytime.
                </p>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item md={12} xs={12}>
                <h2 className="heading">Create an account?</h2>
              </Grid>
              <Grid item md={12} xs={12}>
                <p className="paragraph">Enter OTP received on mobile number</p>
                <input
                  type="tel"
                  value={otp}
                  id="phonecode"
                  required
                  onChange={(e) => handleOtpChange(e.target.value)}
                />

                {invalidOtp && (
                  <p className="errorLabel">
                    Enter a valid otp or resend otp again
                  </p>
                )}
              </Grid>
              <Grid item md={12} className={styles.btnContainer} xs={12}>
                <div id="phone-sign-in-recaptcha"></div>
                <Button
                  className={`primaryBtn ${styles.docBtn}`}
                  onClick={handleOtp}
                  disabled={otp.length !== 6}
                >
                  REGISTER
                </Button>
                <div id="recaptcha-container"></div>
              </Grid>
              <Grid item md={12} className={styles.resendContainer} xs={12}>
                {!resendOtp ? (
                  <p className="smallLabel" onClick={handleResend}>
                    <b className={styles.resend}>RESEND OTP</b> if you haven’t
                    received it yet.
                  </p>
                ) : (
                  <p className="smallLabel">OTP has been re-sent</p>
                )}
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid container>
          {!continueBtn ? (
            <React.Fragment>
              <Grid item md={12} xs={12}>
                <h2 className="heading">Create your account</h2>
              </Grid>
              <Grid item md={12} xs={12}>
                <p className="paragraph">Enter your mobile number</p>
                {/* <input type="text" value={phoneCode} id="phonecode" disabled />
                <input
                  type="tel"
                  id="phone"
                  required
                  autoFocus
                  value={phone}
                  title="A valid phone number is required"
                  onChange={(e) => {
                    handlePhoneNumber(e.target.value);
                  }}
                  onBlur={handleOnPhoneBlur}
                /> */}
                {/* <PhoneInput
                  country={"us"}
                  value={this.state.phone}
                  onChange={(phone) => this.setState({ phone })}
                /> */}

                <div className="phoneInput">
                  <PhoneInput
                    value={phone}
                    preferredCountries={["in", "us", "gb"]}
                    onChange={(phone) => handlePhoneNumber(phone)}
                    onBlur={handleOnPhoneBlur}
                    placeholder=""
                  />
                </div>

                {invalid && (
                  <p className="errorLabel">Enter a valid phone number </p>
                )}
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                className={styles.continuebtnContainer}
              >
                <div id="phone-sign-in-recaptcha"></div>
                <br />
                <Button
                  className={`blackBtn ${styles.docBtn}`}
                  onClick={handleVerification}
                  disabled={sendOtp}
                >
                  {!sendOtp ? (
                    "Continue"
                  ) : (
                    <CircularProgress
                      className={styles.circularProgress}
                      size={22}
                    />
                  )}
                </Button>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item md={12} xs={12}>
                <h2 className="heading">Create an account?</h2>
              </Grid>
              <Grid item md={12} xs={12} className={styles.otpContainer}>
                <p className="paragraph">Enter OTP received on mobile number</p>
                <input
                  type="tel"
                  value={otp}
                  id="phonecode"
                  required
                  autoFocus
                  onChange={(e) => handleOtpChange(e.target.value)}
                />

                {invalidOtp && (
                  <p className="errorLabel">
                    Enter a valid otp or resend otp again
                  </p>
                )}
              </Grid>
              <Grid item md={12} className={styles.resendContainer} xs={12}>
                {!resendOtp ? (
                  <p className="smallLabel" onClick={handleResend}>
                    <b className={styles.resend}>RESEND OTP</b> if you haven’t
                    received it yet.
                  </p>
                ) : (
                  <p className="smallLabel">OTP has been re-sent</p>
                )}
              </Grid>
              <Grid
                item
                md={12}
                className={styles.continuebtnContainer}
                xs={12}
              >
                <div id="phone-sign-in-recaptcha"></div>
                <Button
                  className={`primaryBtn ${styles.docBtn}`}
                  onClick={handleOtp}
                  disabled={otp.length !== 6}
                >
                  REGISTER
                </Button>
                <div id="recaptcha-container"></div>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </MobileView>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userdetail: state.userdetail,
    responses: state.responses,
    points:
      state.responses.length === 0
        ? 0
        : state.responses
            .map((res) => res.points)
            .reduce((sum, agg) => sum + agg, 0),
  };
};

export default connect(mapStateToProps)(withRouter(PhoneVerification));

import * as actions from "./actions";
const initialState = {
  name: "",
  gender: "",
  age: "",
  dob: "",
  uid: "",
  lastAthlete: "",
  lastDayAsses: "",
  lastDateAsses: "",
  lastTimeAsses: "",
  phone: "",
  city: "",
  imgUrl: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case actions.ADD_USERDETAIL:
      return Object.assign({}, state, action.payload);

    case actions.RESET_USERDETAIL:
      return initialState;

    case actions.EDIT_USERDETAIL:
      return Object.assign({}, state, action.payload);

    case actions.FETCH_USERDETAIL:
      return state;

    default:
      return state;
  }
}

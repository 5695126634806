import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NavBar from "../shared_elements/NavBar";
import "../assets/styles/app.scss";
import { Background } from "../shared_elements";
import { Grid, Container, Hidden } from "@material-ui/core";
import { AboutMyOpal, FaqCard, CopyRights } from "../shared_elements";

export default function pageLayoutHoc(HocComponent, extraProps = {}) {
  class PageLayout extends Component {
    constructor() {
      super();
      this.state = {
        showFaqAbout: false,
        showNav: true,
        otherCardsVisible: true,
        windowWidth: window.innerWidth,
      };
    }

    handleResize = (e) => {
      this.setState({ windowWidth: window.innerWidth });
    };

    componentWillUnmount() {
      window.addEventListener("resize", this.handleResize);
    }
    componentDidMount() {
      const { pathname } = this.props.location;
      const isMobile = window.innerWidth < 600 ? true : false;
      window.addEventListener("resize", this.handleResize);
      this.setState({ showFaqAbout: false });
      const conditionAll =
        pathname === "/" ||
        pathname === "/assessment" ||
        pathname === "/congrats" ||
        pathname === "/user-detail" ||
        pathname === "/sign-in" ||
        pathname === "/doctor" ||
        pathname === "/server-down";
      if (conditionAll) {
        if (isMobile && pathname !== "/") {
          this.setState({ showFaqAbout: false });
        } else {
          this.setState({ showFaqAbout: true });
        }
      }

      if (pathname) {
        if (
          conditionAll ||
          pathname === "/pdf" ||
          pathname === "/about" ||
          pathname === "/faq" ||
          pathname === "/user-profile" ||
          pathname === "/user-score" ||
          pathname === "/terms-&-condition" ||
          pathname === "/privacy-policy" ||
          pathname.split("/")[1] === "pdf"
        ) {
          if (pathname === "/server-down") {
            this.setState({ showNav: false });
          } else {
            this.setState({ showNav: true });
          }
        } else {
          this.setState({ showNav: false });
        }
      } else {
        this.setState({ showNav: false });
      }

      if (pathname === "/assessment" || pathname === "/server-down") {
        this.setState({ otherCardsVisible: false });
      }
    }
    render() {
      const {
        showFaqAbout,
        otherCardsVisible,
        showNav,
        windowWidth,
      } = this.state;

      const isMobile = windowWidth < 600 ? true : false;

      return (
        <>
          {showNav && <NavBar visible={otherCardsVisible} />}
          <main>
            <Background />
            {showFaqAbout ? (
              <section className="sectionPage">
                <div className="sectionContainer">
                  <Container maxWidth="md">
                    <Grid
                      container
                      justify="center"
                      spacing={isMobile ? 0 : 3}
                      direction={isMobile ? "column-reverse" : "row"}
                    >
                      <Grid item md={8} sm={12} xs={12}>
                        <HocComponent {...this.props} />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Grid
                          container
                          justify={"center"}
                          spacing={isMobile ? 1 : 3}
                          direction="row"
                        >
                          <Grid item xs={6} md={12}>
                            <AboutMyOpal visible={otherCardsVisible} />
                          </Grid>
                          <Grid item xs={6} md={12}>
                            <FaqCard visible={otherCardsVisible} />
                          </Grid>
                          <Hidden mdUp>
                            <Grid item xs={6} sm={6} container></Grid>
                          </Hidden>
                          <Grid item md={12} xs={6} sm={6} container>
                            <CopyRights visible={otherCardsVisible} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </section>
            ) : (
              <section className="sectionContainerSecond">
                <HocComponent {...this.props} />{" "}
              </section>
            )}
          </main>
        </>
      );
    }
  }

  return withRouter(PageLayout);
}

import PrintPdf from "./containers/PrintPdf";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";

export const printPdfRoutes = [
  {
    path: "/pdf/:id",
    component: pageLayoutHoc(PrintPdf),
    key: "pdfId",
  },
  {
    path: "/pdf",
    component: pageLayoutHoc(PrintPdf),
    key: "pdf",
  },
];

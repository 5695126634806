import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "../src/store/configureStore";
import { Provider } from "react-redux";
import { getCookie } from "./shared_elements/cookie";
import firebase from "./shared_elements/firebase/firebase";
import { ADD_USERDETAIL } from "./shared_elements/redux/userdetails/actions";
import { LanguageProvider } from "./shared_elements/multiLanguage/containers/language";
const store = configureStore();
store.subscribe(() => {
  // console.log(store.getState(), "store");
});

const uid = getCookie("uid");
if (uid) {
  firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .get()
    .then((doc) => {
      const userData = doc.data();
      if (userData) {
        let {
          name,
          phone,
          imgUrl,
          city,
          dob,
          lastAthlete,
          age,
          gender,
        } = userData;
        name = name ? name : "";
        phone = phone ? phone : "";
        imgUrl = imgUrl ? imgUrl : "";
        city = city ? city : "";
        dob = dob ? dob : "";
        lastAthlete = lastAthlete ? lastAthlete : "";
        age = age ? age : "";
        gender = gender ? gender : "";
        store.dispatch({
          type: ADD_USERDETAIL,
          payload: { name, phone, city, dob, lastAthlete, age, imgUrl, gender },
        });
      }
    });
} else {
  // console.log("user is not logged");
}

let cssRule =
  "color:rgb(74,98,163);" +
  "font-size: 30px;" +
  "font-weight: bold;" +
  "filter: dropshadow(color=rgb(74,98,163), offx=1, offy=1);";
setTimeout(console.log.bind(console, "%cStop !!!", cssRule), 0);
cssRule =
  "color:rgb(0,0,0);" +
  "font-size: 14px;" +
  "font-weight: medium;" +
  "filter: dropshadow(color=rgb(0,0,0), offx=1, offy=1);";
setTimeout(
  console.log.bind(
    console,
    "%cThis console is for developers , you cannot hack these tests",
    cssRule
  ),
  0
);

const jsx = (
  <Provider store={store}>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </Provider>
);

ReactDOM.render(jsx, document.getElementById("root"));

serviceWorker.unregister();

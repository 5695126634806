import React from "react";
import { Grid } from "@material-ui/core";
import { staticImages } from "../utils";

export default function Page404() {
  return (
    <div className="serverDown">
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <img src={staticImages.serverDown} alt="server down" />
        </Grid>
        <Grid item md={12} sm={12}>
          <h2 className="heading2"> Server down :( </h2>
        </Grid>
        <Grid item md={12} sm={12}>
          <p className="paragraph">
            We’re fixing few things and will be back in 2 hours.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

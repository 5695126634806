import React, { useState } from "react";
import { Grid, withStyles, Slider } from "@material-ui/core";
import PropTypes from "prop-types";
import { staticImages } from "../utils";

const MySlider = withStyles({
  root: {
    color: "#CCCCCC",
    borderRadius: "10px",
    padding: "13px 0",
  },
  thumb: {
    height: 58,
    width: 58,
    background: `url(${staticImages.sliderButton})`,
    marginTop: -26,
    marginLeft: -23,
    "&:focus, &:hover, &$active": {
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0)",
    },
    "& .bar": {
      height: 14,
      width: 1,
      backgroundColor: "#344880",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-0% + 15px)",
    textAlign: "start",
    whiteSpace: "nowrap",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 3,
    borderRadius: "10px",
  },
  rail: {
    color: "#CCCCCC",
    opacity: 1,
    borderRadius: "10px",
    height: 3,
  },
})(Slider);

function CustomSlider(props) {
  const [value, SetValue] = useState(
    props.initialValue ? props.initialValue : 0
  );

  const {
    min,
    max,
    step,
    valueLabelDisplay,
    defaultValue,
    sendBackValue,
  } = props;
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} sm={12}>
          <MySlider
            valueLabelDisplay={valueLabelDisplay}
            defaultValue={defaultValue}
            min={min}
            value={value}
            onChange={(e, v) => {
              sendBackValue(v);
              SetValue(v);
            }}
            max={max}
            step={step}
            getAriaValueText={() => {
              return `${value}`;
            }}
            valueLabelFormat={() => {
              return `${value} Years`;
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

CustomSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.arrayOf(PropTypes.object),
  step: PropTypes.number,
  valueLabelDisplay: PropTypes.oneOf(["auto", "on", "off"]),
  defaultValue: PropTypes.number,
  initialValue: PropTypes.number,
  sendBackValue: PropTypes.func,
};

CustomSlider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  valueLabelDisplay: "off",
  defaultValue: 50,
  initialValue: 50,
};

export default CustomSlider;

import Home from "./containers/Home";
import ServerDownContainer from "./containers/ServerDownContainer";
// import Test from "./containers/Test";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";

export const homeRoutes = [
  {
    path: "/",
    component: pageLayoutHoc(Home),
    key: "home",
  },
  {
    path: "/server-down",
    component: pageLayoutHoc(ServerDownContainer),
    key: "serverDown",
  },
  // {
  //   path: "/test",
  //   component: pageLayoutHoc(Test),
  //   key: "test",
  // },
];

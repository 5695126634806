import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import styles from "../../../assets/styles/signin.module.scss";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import { MobileView, BrowserView } from "../../../shared_elements";
import useAddressPredictions from "./useAddressPredictions";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function SearchNearbyDoctors() {
  const [input, setInput] = useState("");
  const [showDoctor, setShowDoctor] = useState(false);

  const handleShowDoctors = () => {
    if (input && input.length !== 0) {
      window.open(
        `https://www.google.com/maps/search/nearby+children's+rheumatologists+${input
          .split(", ")
          .join("+")}`
      );
    }
  };

  const predictions = useAddressPredictions(input);
  const onTagsChange = (e, v) => {
    if (showDoctor) {
      setShowDoctor(false);
    }

    setInput(v);
  };

  const onTagClicked = () => {
    setShowDoctor(true);
  };
  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <h2 className="heading">Consult a doctor?</h2>
      </Grid>
      <Grid item md={12} xs={12}>
        <p className="paragraph">Enter your city name</p>
      </Grid>
      <Grid item md={6} xs={12}>
        <>
          <Autocomplete
            id="custom-input-demo"
            options={predictions}
            className="autocomplete"
            targetorigin={{ vertical: "bottom", horizontal: "left" }}
            onChange={onTagClicked}
            onInputChange={onTagsChange}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input type="text" {...params.inputProps} />
              </div>
            )}
          />
        </>
      </Grid>
      <BrowserView>
        <Grid item md={12} className={styles.btnContainer} xs={12}>
          {showDoctor && (
            <Button
              className={`primaryBtn ${styles.docBtn}`}
              endIcon={<LaunchOutlinedIcon />}
              onClick={handleShowDoctors}
            >
              SHOW ME DOCTORS
            </Button>
          )}
        </Grid>
        <Grid item md={12} className={styles.btmPargraph} xs={12}>
          <p className="paragraph">GOOGLE</p>
          <p className="paragraph">
            Myopal is a independent CMAS tool and is not clinically accurate.
            Consulting a doctor is at your own discretion. We are{" "}
            <b>NOT ASSOCIATED</b> with Practo or any such organisation.
          </p>
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid item md={12} className={styles.docbtnContainer} xs={12}>
          {showDoctor && (
            <Button
              className={`blackBtn ${styles.docBtn}`}
              endIcon={<LaunchOutlinedIcon />}
              onClick={handleShowDoctors}
            >
              SHOW ME DOCTORS
            </Button>
          )}
        </Grid>
        <Grid item md={12} className={styles.btmPargraph} xs={12}>
          <p className="secondaryText">
            <b>Note:</b>
          </p>
          <p className="secondaryText">
            Myopal is a independent CMAS tool and is not clinically accurate.
            Consulting a doctor is at your own discretion. We are{" "}
            <b>NOT ASSOCIATED</b> with Practo or any such organisation.
          </p>
        </Grid>
      </MobileView>
    </Grid>
  );
}

import React from "react";
import styles from "../../../assets/styles/signin.module.scss";
import { Grid, Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import PhoneVerification from "./PhoneVerification";

export default function SignInCard() {
  const history = useHistory();

  return (
    <div className={styles.signInCard}>
      <Grid container alignItems="center">
        <Grid item className={styles.header} md={12} xs={12} sm={12}>
          <div className={`${styles.backButton} flexCentered`}>
            <Button
              startIcon={<ArrowBackIosIcon className={styles.icon} />}
              className={` heading5 flexCentered ${styles.btn}`}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </div>
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <PhoneVerification />
        </Grid>
      </Grid>
    </div>
  );
}

import React from "react";
import PropTypes from "prop-types";
function Header(props) {
  return (
    <>
      <h1 className="heading1">{props.content}</h1>
    </>
  );
}

Header.propTypes = {
  content: PropTypes.string,
};

Header.defaultProps = {
  content: "Header",
};

export default Header;

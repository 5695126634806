import React from "react";
import Page404 from "../../../shared_elements/Page404";

export default function Page404Container() {
  return (
    <>
      <Page404 />
    </>
  );
}

import About from "./containers/About";
import Privacy from "./containers/Privacy";
import Terms from "./containers/Terms";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";
export const aboutRoutes = [
  {
    path: "/about",
    component: pageLayoutHoc(About),
    key: "about",
  },
  {
    path: "/privacy-policy",
    component: pageLayoutHoc(Privacy),
    key: "privacy-policy",
  },
  {
    path: "/terms-&-condition",
    component: pageLayoutHoc(Terms),
    key: "about",
  },
];

import React, { useEffect, useState, Fragment } from "react";
import { makeStyles, Modal } from "@material-ui/core";
import { useIdleTimer } from "react-idle-timer";
import moment from "moment";
import { connect } from "react-redux";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import AssessmentCard from "../components/AssessmentCard";
import { LoadingCard } from "../../../shared_elements";
import { getCookie, setCookie } from "../../../shared_elements/cookie";
import { CLEAR_RESPONSE } from "../../../shared_elements/redux/responses/actions";
import { fireDbFetch } from "../../../shared_elements/firebase/fireFunctions";

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  root: {
    backgroundColor: "white",
  },
  btn: {
    marginTop: "40px",
  },
  icon: {
    width: "100px",
    height: "100px",
    color: "#DB7473",
  },
}));

function AssessmentPage(props) {
  const [question, SetQuestion] = useState([]);
  const [questionNum, SetQuestionNum] = useState(0);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [expires, SetExpires] = React.useState("2 hours");

  const handleExit = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOnIdle = () => {
    const time = getCookie("sessionExpiry");
    if (time) {
      setOpen(true);
      SetExpires(String(moment(time).endOf("mm").fromNow(true), "time"));
    } else {
      SetExpires("expired");
      props.dispatch({ type: CLEAR_RESPONSE });
      props.history.push("/");
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  useEffect(() => {
    setCookie("sessionExpiry", String(moment().format()), 0.2);
    let isSubscribed = true;

    if (question.length === 0) {
      const callbackOnFail = () => {
        props.history.push("/server-down");
      };

      const Exercises = fireDbFetch(isSubscribed, "assesment", callbackOnFail);
      Exercises.then((result) => {
        const { exercises } = result;
        if (isSubscribed) {
          if (result && exercises) {
            SetQuestion(exercises);
          } else {
            // console.log("error fetching assessments");
          }
        }
      }).catch((err) => {
        // console.error(err, "error");
      });
    }

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextQuestion = (points) => {
    if (questionNum < question.length - 1) {
      SetQuestionNum(questionNum + 1);
    } else {
      props.history.push("/congrats");
    }
  };

  return (
    <Fragment>
      <Fragment>
        {question.length !== 0 ? (
          <AssessmentCard
            question={question[questionNum]}
            nextQuestion={nextQuestion}
            maxExercise={question.length}
          />
        ) : (
          <LoadingCard />
        )}
      </Fragment>

      <Modal
        open={open}
        hideBackdrop={true}
        onClose={handleClose}
        closeAfterTransition
        disableAutoFocus={true}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <h2 className="heading2">
            {expires === "expired"
              ? "Your session has already"
              : "Your online session will expire in"}
          </h2>
          <AccessTimeIcon className={classes.icon} />
          <h1 className="largeHeading">{expires}</h1>
          <button
            className={`primaryBtn dialogBtn ${classes.btn}`}
            onClick={handleExit}
          >
            CONTINUE
          </button>
          <button className="secondaryBtn dialogBtn" onClick={handleClose}>
            CANCEL
          </button>
        </div>
      </Modal>
    </Fragment>
  );
}

export default connect()(AssessmentPage);

import React, { Component } from "react";
import { Grid, Container } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <Container fixed>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <h1>Oops..! Something went wrong</h1>
                <h2>An error occurred. Please try again later.</h2>
                <Link to="/" onClick={() => this.setState({ error: false })}>
                  Return to your home
                </Link>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
export default withRouter(ErrorBoundary);

import React, { useState, useEffect, Fragment } from "react";
import styles from "../../../assets/styles/assessment.module.scss";
import { Grid, Button, useMediaQuery } from "@material-ui/core";
import { Timer } from "../../../shared_elements";
import { START_TIMER } from "../../../shared_elements/redux/timer/actions";
import { connect } from "react-redux";

function AssessmentCounter(props) {
  const [start, SetStart] = useState(false);
  const isMobile = !useMediaQuery("(min-width:600px)");

  const [counter, SetCounter] = useState(
    props.seconds < 0 || props.seconds > 120 ? 120 : props.seconds
  );
  const [duplicateCounter, SetDuplicateCounter] = useState(
    String(props.seconds < 0 || props.seconds > 120 ? 120 : props.seconds)
  );
  const handleStart = () => {
    props.dispatch({ type: START_TIMER, payload: 0 });
    SetStart(true);
    SetCounter(counter - 1);
  };

  const { handleExerciseDone, showCounter } = props;

  useEffect(() => {
    let timer;
    if (counter > -1 && start) {
      timer = setTimeout(() => {
        SetCounter(counter - 1);
        SetDuplicateCounter(
          "0".repeat(3 - String(counter).length).concat(String(counter))
        );
        if (counter === 0 && showCounter) {
          handleExerciseDone(0);
          clearTimeout(timer);
        }
      }, 1000);
    } else {
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [counter, start, handleExerciseDone, showCounter]);
  return (
    <div className={styles.assessmentCounter}>
      <Grid container>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          container
          alignItems="center"
          spacing={1}
        >
          {props.showCounter && (
            <Fragment>
              <Grid
                item
                sm={10}
                xs={12}
                md={6}
                container
                justify={isMobile ? "center" : "flex-start"}
              >
                <Timer content={duplicateCounter} />
              </Grid>
              <Grid item sm={2} xs={12} md={6} className="textLeft">
                <p className={styles.time}>seconds</p>
              </Grid>
            </Fragment>
          )}
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          container
          justify={isMobile ? "center" : "flex-end"}
          direction="row"
          alignItems="center"
        >
          {start ? (
            <Button
              className="secondaryBtn"
              onClick={() => props.handleExerciseDone(counter + 1)}
            >
              STOP
            </Button>
          ) : (
            <Button className="primaryBtn" onClick={handleStart}>
              BEGIN
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default connect()(AssessmentCounter);

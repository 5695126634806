import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ErrorBoundary from "./utils/ErrorBoundary";
import { homeRoutes } from "./features/home/homeRoutes";
import { aboutRoutes } from "./features/about/aboutRoutes";
import { faqRoutes } from "./features/faq/faqRoutes";
import { userDetailRoutes } from "./features/userdetails/userDetailRoutes";
import { assessmentRoutes } from "./features/assessment/assessmentRoutes";
import { signInRoutes } from "./features/userAuthentication/userAuthenticationRoutes";
import { printPdfRoutes } from "./features/pdf/printPdfRoutes";
import SomethingWrong from "./shared_elements/SomethingWrong";
import "./assets/styles/app.scss";

function App() {
  return (
    <>
      <Router>
        <ErrorBoundary>
          <Switch>
            {printPdfRoutes.map(({ path, component, key }, index) => (
              <Route exact path={path} component={component} key={key} />
            ))}
            {homeRoutes.map(({ path, component, key }, index) => (
              <Route exact path={path} component={component} key={key} />
            ))}
            {aboutRoutes.map(({ path, component, key }, index) => (
              <Route exact path={path} component={component} key={key} />
            ))}
            {faqRoutes.map(({ path, component, key }, index) => (
              <Route exact path={path} component={component} key={key} />
            ))}
            {userDetailRoutes.map(({ path, component, key }, index) => (
              <Route exact path={path} component={component} key={key} />
            ))}
            {assessmentRoutes.map(({ path, component, key }, index) => (
              <Route exact path={path} component={component} key={key} />
            ))}
            {signInRoutes.map(({ path, component, key }, index) => (
              <Route exact path={path} component={component} key={key} />
            ))}
            {<Route path="*" exact={true} component={SomethingWrong} />}
          </Switch>
        </ErrorBoundary>
      </Router>
    </>
  );
}

export default App;

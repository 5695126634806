import React, { useState, useEffect, Fragment, useRef } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";

import styles from "../../../assets/styles/assessment.module.scss";

import { BrowserView, MobileView } from "../../../shared_elements";

function AssessmentVideo(props) {
  const [show, SetShow] = useState(false);
  const videoRef = useRef(null);

  const handleVideoChange = () => {
    videoRef.current.currentTime = videoRef.current.duration - 0.1;
  };
  return (
    <div className={styles.assessmentVideo}>
      <Grid container justify="center">
        <MobileView>
          <Grid item>
            <Fragment>
              <div className={`${styles.videoWrapper} ${show ? "hidden" : ""}`}>
                <video
                  loop={false}
                  ref={videoRef}
                  autoPlay={true}
                  className="videoPlayer"
                  disablePictureInPicture
                  controls
                  onCanPlay={() => {
                    SetShow(false);
                  }}
                  controlsList="nodownload"
                >
                  <source src={props.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Fragment>
          </Grid>
        </MobileView>
        <BrowserView>
          <Grid item md={12}>
            <Fragment>
              {show && <Skeleton variant="rect" width={"100%"} height={257} />}
              <div className={`${styles.videoWrapper} ${show ? "hidden" : ""}`}>
                <video
                  autoPlay={true}
                  className="videoPlayer"
                  disablePictureInPicture
                  loop={false}
                  controls
                  onPlay={() => {
                    videoRef.current.currentTime = 0;
                  }}
                  ref={videoRef}
                  onCanPlay={() => {
                    SetShow(false);
                  }}
                  controlsList="nodownload"
                  onEnded={(e) => {
                    handleVideoChange(e);
                  }}
                >
                  <source src={props.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Fragment>
          </Grid>
        </BrowserView>
      </Grid>
    </div>
  );
}

export default AssessmentVideo;

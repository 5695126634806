import React, { Component } from "react";
import PdfView from "../components/PdfView";
import styles from "../../../assets/styles/pdf.module.scss";
import ReactToPrint from "react-to-print";
import { Button, Container, Grid, IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { staticImages } from "../../../utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default class PrintPdf extends Component {
  state = {
    date: new Date().getTime(),
  };
  exportPdf = () => {
    html2canvas(document.querySelector("#capture"), { scale: 1 }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        let pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(imgData, "PNG", 0, 0);
        pdf.save(`myopal-report-${this.state.date}.pdf`);
      }
    );
  };

  handleDate = (date) => {
    if (date) {
      this.setState({ date });
    }
  };
  render() {
    return (
      <>
        <Container className={styles.contianerPdf}>
          <Grid container className={styles.buttonGroup}>
            <Grid item md={4} xs={12} className={styles.btnWrap}>
              <IconButton
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <ArrowBackIcon className={styles.backIcon} />
              </IconButton>
            </Grid>
            <Grid item container justify="flex-end" md={8} xs={12}>
              <ReactToPrint
                trigger={() => (
                  <span className={styles.btnWrap}>
                    <Button
                      className={`primaryBtn ${styles.Btn}`}
                      startIcon={
                        <img
                          src={staticImages.bluePrint}
                          alt="print"
                          className={styles.icon}
                        />
                      }
                    >
                      Print Report
                    </Button>
                    <br />
                    <br />
                  </span>
                )}
                content={() => this.componentRef}
              />
              <span className={styles.btnWrap}>
                <Button
                  className={`primaryBtn ${styles.Btn}`}
                  startIcon={
                    <img
                      src={staticImages.blueDownload}
                      alt="download"
                      className={styles.icon}
                    />
                  }
                  onClick={this.exportPdf}
                >
                  Download Pdf
                </Button>
              </span>
            </Grid>
          </Grid>
          <div className={styles.page} ref={(el) => (this.componentRef = el)}>
            <div className="div-to-pdf">
              <div className={styles.pdf} id="capture">
                <PdfView dateOf={this.handleDate} />
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

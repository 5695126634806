import React from "react";
import { Grid, Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import { staticImages } from "../utils";
import { PropTypes } from "prop-types";
import { Content } from "./multiLanguage/containers/language";
import { BrowserView } from "./";

function FaqCard(props) {
  return (
    <div className={`faqcard ${props.visible ? "" : "disableCard"}`}>
      <Grid container alignItems="center" className="faqheader">
        <Grid container alignItems="center" spacing={1}>
          <Grid item md={10} xs={12} sm={8}>
            <Link to="/faq" className="link">
              <h1 className="heading2">
                <Content path="routes.home.faq_card.header" />
              </h1>
            </Link>
          </Grid>

          <BrowserView>
            <Grid item md={2} xs={3} className="faqicon textCenter">
              <img src={staticImages.faqGroup} alt="faq icon"></img>
            </Grid>
          </BrowserView>
        </Grid>

        <BrowserView>
          <Grid item md={12} sm={12}>
            <Link to="/faq" className="link">
              <div className="arrowBtnGroup">
                <Button
                  className="metaText"
                  endIcon={<ArrowForwardIosIcon className="arrowIcon" />}
                >
                  <Content path="routes.home.faq_card.button" />
                </Button>
              </div>
            </Link>
          </Grid>
        </BrowserView>
      </Grid>
    </div>
  );
}

FaqCard.propTypes = {
  visible: PropTypes.bool,
};

FaqCard.defaultProps = {
  visible: true,
};

export default FaqCard;

import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { staticImages } from "../../../utils";
import styles from "../../../assets/styles/assessment.module.scss";
import AssessmentOptions from "./AssessmentOptions";
import animationData from "../containers/celebration.json";
import Lottie from "react-lottie-player";

export default function AssessmentQA(props) {
  const [questions, SetQuestions] = useState(
    props.questions.map((data) => Object.assign(data, {}, { checked: false }))
  );
  const [disabled, Setdisabled] = useState(true);
  const [points, SetPoints] = useState(0);
  const [showLottie, SetShowLottie] = useState(true);

  const isMobile = !useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (!showLottie) {
      SetShowLottie(true);
    }
    if (
      questions &&
      questions.length !== 0 &&
      questions[0].hasOwnProperty("checked") &&
      questions.some((q) => q.checked === true)
    ) {
      Setdisabled(false);
    } else {
      Setdisabled(true);
    }
  }, [questions, showLottie]);

  const handleClick = (description) => {
    let changeCheck, totalPoints, checkedQuestions;
    if (props.multiSelect) {
      changeCheck = questions.map((quest) => {
        return String(quest.description) === String(description)
          ? Object.assign({}, quest, { checked: !quest.checked })
          : Object.assign({}, quest);
      });
      checkedQuestions = changeCheck.filter((quest) => quest.checked === true);
      if (checkedQuestions && checkedQuestions.length !== 0) {
        totalPoints = checkedQuestions
          .map((quest) => quest.points)
          .reduce((sum, agg) => sum + agg, 0);
      } else {
        totalPoints = 0;
      }
    } else {
      changeCheck = questions.map((quest) => {
        return String(quest.description) === String(description)
          ? Object.assign({}, quest, { checked: !quest.checked })
          : Object.assign({}, quest, { checked: false });
      });

      checkedQuestions = changeCheck.find((quest) => quest.checked === true);
      if (checkedQuestions && Object.values(checkedQuestions).includes(true)) {
        totalPoints = checkedQuestions.points;
      }
    }

    SetQuestions(changeCheck);
    SetPoints(totalPoints);
  };

  const handleSubmit = () => {
    let userOptions,
      userPoints = 0,
      questionNo = 0;
    if (
      questions &&
      questions.length !== 0 &&
      questions[0].hasOwnProperty("checked") &&
      questions.some((q) => q.checked === true)
    ) {
      userOptions = questions.filter((q) => q.checked === true);
      userPoints = points;
      questionNo = props.questionNo;
      if (
        userOptions.length !== 0 &&
        userOptions[0].hasOwnProperty("description")
      ) {
        userOptions = userOptions.map((options) => options.description);
      } else {
        // alert("error");
        // console.log("error in fetching QA");
      }
    }
    const responseData = {
      userOptions,
      userPoints,
      questionNo,
    };
    props.pointsScored(responseData);
  };

  return (
    <div className={styles.optionContainer}>
      {showLottie && (
        <div className={styles.lottieAnim}>
          <Lottie
            loop={false}
            animationData={animationData}
            onComplete={() => {
              SetShowLottie(false);
            }}
            play
            style={{ height: "99%" }}
          ></Lottie>
        </div>
      )}

      <div className={styles.optionsWrapper}>
        <Grid container>
          <Grid item md={12}>
            <p className={`heading3 ${styles.heading}`}>
              {isMobile
                ? "Well Done !"
                : "Well done! Now let's rate this activity."}
            </p>
          </Grid>
          <Grid item md={12}>
            <p className={`${styles.question} heading5`}>
              Please select which closely matches to your understanding or
              observation.
            </p>
            {props.multiSelect && (
              <p className={`${styles.question} heading5`}>
                Note : Select all positions achieved
              </p>
            )}
          </Grid>
          {questions.map((data) => {
            return (
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                key={data.description}
                onClick={() => handleClick(data.description)}
              >
                <div className={styles.optionRow}>
                  <AssessmentOptions
                    question={data.description}
                    point={data.points}
                    checked={data.checked}
                  />
                </div>
              </Grid>
            );
          })}
          <Grid item md={12} sm={12} xs={12} style={{ padding: "10px" }}></Grid>
          <Grid item md={12} sm={12} xs={12} className={styles.bottomBtnGroup}>
            <Grid container className="flexCentered">
              {!isMobile && (
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  className="textLeft"
                  container
                  spacing={2}
                >
                  {props.showCounter ? (
                    <>
                      <Grid item>
                        <img
                          src={staticImages.stopWatch}
                          alt="clock"
                          style={{ width: 22 }}
                        ></img>
                      </Grid>
                      <Grid item>
                        <p className={styles.time}>{props.seconds} seconds</p>
                      </Grid>
                    </>
                  ) : (
                    <Grid item></Grid>
                  )}
                </Grid>
              )}

              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                className={isMobile ? "textCenter" : "textRight"}
                container
                justify="flex-end"
              >
                <button
                  className="primaryBtn"
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {Number(props.questionNo) === 14 ? "SUBMIT SCORE" : "NEXT"}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

import React from "react";

export default function Boy(props) {
  return (
    <div>
      <svg
        width="12"
        height="28"
        viewBox="0 0 12 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.17656 4.51554C8.48971 3.13692 7.65348 1.75907 6.30879 1.43802C4.96409 1.11697 3.62015 1.9743 3.307 3.35292C2.99385 4.73153 3.83008 6.10938 5.17477 6.43043C6.51947 6.75148 7.86341 5.89416 8.17656 4.51554Z"
          fill={props.color}
        />
        <path
          d="M1.48287 18.584C1.54466 18.584 1.60722 18.5777 1.67055 18.5634C2.15017 18.4581 2.45601 17.9735 2.35252 17.481C1.93778 15.5007 1.77868 13.9645 1.77868 12.7935C1.77868 11.7855 1.89684 11.0475 2.05903 10.5249C2.26524 9.87403 2.51625 9.55414 2.73637 9.37044V13.7769V15.9354V25.7341C2.73637 26.5116 3.35114 27.1427 4.11034 27.1427C4.86954 27.1427 5.48509 26.5124 5.48509 25.7341V16.8159H6.00024V25.7341C6.00024 26.5116 6.61501 27.1427 7.37421 27.1427C8.13341 27.1427 8.74896 26.5124 8.74896 25.7341V15.9346V13.7761V9.3546C8.88412 9.46546 9.02854 9.62857 9.17529 9.88512C9.45487 10.384 9.72287 11.2771 9.7221 12.7927C9.7221 13.9638 9.56222 15.4999 9.14903 17.4802C9.04553 17.9727 9.35138 18.4573 9.83099 18.5626C9.89433 18.5769 9.95611 18.5832 10.0179 18.5832C10.4288 18.5832 10.7972 18.2902 10.8868 17.8626C11.3208 15.7873 11.5 14.1316 11.5 12.7927C11.5 11.6398 11.3664 10.7189 11.1362 9.97143C10.7949 8.85418 10.1986 8.12096 9.57844 7.72742C8.96058 7.32993 8.3767 7.26738 8.06081 7.26738C8.03764 7.26738 8.01756 7.26817 7.99748 7.26817C7.96195 7.26421 7.92565 7.26025 7.88858 7.26025H3.59443C3.55813 7.26025 3.5226 7.26342 3.48784 7.26817C3.47163 7.26817 3.45695 7.26738 3.43919 7.26738C3.2268 7.26738 2.90087 7.29509 2.51934 7.43129C1.94782 7.62924 1.26044 8.10908 0.781598 8.98087C0.29812 9.85186 0.00154466 11.0657 0 12.7935C0.00077233 14.1332 0.179953 15.7881 0.614003 17.8634C0.702821 18.291 1.07199 18.584 1.48287 18.584Z"
          fill={props.color}
        />
      </svg>
    </div>
  );
}

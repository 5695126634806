import React from "react";
import { Grid, Button } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import styles from "../../../assets/styles/assessment.module.scss";
import PropTypes from "prop-types";
import parse from "html-react-parser";

function AssessmentInfo(props) {
  return (
    <div className={styles.assessmentInfo}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <h3 className={`${styles.heading} heading3`}>{props.name}</h3>
        </Grid>
        <Grid item md={12} xs={12}>
          <p className={`heading5 ${styles.subheading}`}>
            {parse(props.description)}
          </p>
        </Grid>
        <Grid item md={12} container className="flexCentered" spacing={1}>
          <Grid item>
            <Button
              className={styles.iconButton}
              startIcon={<ErrorIcon className={styles.warningIcon} />}
            >
              TIP
            </Button>
          </Grid>
          <Grid item>
            <p className={styles.tip}>{props.tip}</p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

AssessmentInfo.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  tip: PropTypes.string,
};

AssessmentInfo.defaultProps = {
  name: "",
  description: "",
  tip: "",
};

export default AssessmentInfo;

import UserProfilePage from "./containers/UserProfilePage";
import UserDetail from "./containers/UserDetail";
import UserScorePage from "./containers/UserScorePage";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";
export const userDetailRoutes = [
  {
    path: "/user-profile",
    component: pageLayoutHoc(UserProfilePage),
    key: "user-profile",
  },
  {
    path: "/user-detail",
    component: pageLayoutHoc(UserDetail),
    key: "user-detail",
  },
  {
    path: "/user-score",
    component: pageLayoutHoc(UserScorePage),
    key: "user-score",
  },
];

import React, { Component } from "react";
import styles from "../../../assets/styles/signin.module.scss";
import { Grid, Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ConsultDoctor from "./ConsultDoctor";
import { withRouter } from "react-router-dom";

class SearchNearbyDoctors extends Component {
  render() {
    return (
      <div className={styles.signInCard}>
        <Grid container alignItems="center">
          <Grid item className={styles.header} md={12} xs={12} sm={12}>
            <div className={`${styles.backButton} flexCentered`}>
              <Button
                startIcon={<ArrowBackIosIcon className={styles.icon} />}
                className={` heading5 flexCentered ${styles.btn}`}
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <ConsultDoctor />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(SearchNearbyDoctors);

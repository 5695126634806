import AssessmentPage from "./containers/AssessmentPage";
import CongratsPage from "./containers/CongratsPage";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";
export const assessmentRoutes = [
  {
    path: "/assessment",
    component: pageLayoutHoc(AssessmentPage),
    key: "assessment",
  },
  {
    path: "/congrats",
    component: pageLayoutHoc(CongratsPage),
    key: "congrats",
  },
];

import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, Button, useMediaQuery } from "@material-ui/core";
import Lottie from "react-lottie-player";
import styles from "../../../assets/styles/assessment.module.scss";
import AssessmentHeader from "./AssessmentHeader";
import AssessmentInfo from "./AssessmentInfo";
import AssessmentCounter from "./AssessmentCounter";
import AssessmentVideo from "./AssessmentVideo";
import AssessmentQA from "./AssessmentQA";
import animationData from "../containers/celebration.json";
import { ADD_RESPONSE } from "../../../shared_elements/redux/responses/actions";
import { staticImages } from "../../../utils";

function AssessmentCard(props) {
  const isMobile = !useMediaQuery("(min-width:600px)");
  const [exerciseDone, SetExerciseDone] = useState(false);
  const [exerciseTime, SetExerciseTime] = useState(0);
  const [displayQuestion, SetDisplayQuestion] = useState(false);
  const [showLottie, SetShowLottie] = useState(true);
  const { timebound } = props.question;
  const handleExerciseDone = (time) => {
    SetExerciseDone(true);
    if (time !== props.question.maxTime) {
      SetExerciseTime(props.question.maxTime - time);
    }
  };
  const pointsScored = (responseData) => {
    if (
      responseData &&
      responseData.hasOwnProperty("userOptions") &&
      responseData.hasOwnProperty("userPoints") &&
      responseData.hasOwnProperty("questionNo")
    ) {
      const data = {
        questionName: props.question.name,
        questionNo: responseData.questionNo,
        userResponse: responseData.userOptions,
        points: responseData.userPoints,
      };

      props.dispatch({ type: ADD_RESPONSE, payload: data });
    }
    props.nextQuestion(props.points);
    SetExerciseDone(false);
    SetDisplayQuestion(false);
  };
  const handleNextStep = () => {
    SetDisplayQuestion(true);
  };
  return (
    <div className={styles.assessmentCard}>
      <AssessmentHeader
        percent={props.question.id}
        points={props.points}
        maxExercise={props.maxExercise}
      />
      {!displayQuestion && !exerciseDone && (
        <>
          <AssessmentVideo url={props.question.media} />
          <AssessmentInfo
            name={props.question.name}
            description={props.question.description}
            tip={props.question.tip}
          />
          <AssessmentCounter
            seconds={props.question.maxTime}
            handleExerciseDone={handleExerciseDone}
            showCounter={timebound}
          />
        </>
      )}
      {!displayQuestion && exerciseDone && (
        <div className={styles.assessmentTime}>
          {showLottie && (
            <div className={styles.lottieAnim}>
              <Lottie
                loop={false}
                animationData={animationData}
                onComplete={() => {
                  SetShowLottie(false);
                }}
                play
                style={{ height: "99%" }}
              ></Lottie>
            </div>
          )}

          <Grid container className={styles.otherComp}>
            <Grid item md={12} sm={12} xs={12} className={styles.heading}>
              {isMobile && (
                <div className={styles.headingClap}>
                  <img src={staticImages.clap} alt="clap" />
                </div>
              )}

              <h3>Well done!</h3>
              <p>You can proceed to next step</p>
            </Grid>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={`textRight ${styles.nextBtn}`}
            >
              <Button className="primaryBtn" onClick={handleNextStep}>
                NEXT
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      {displayQuestion && (
        <AssessmentQA
          questions={props.question.remarks}
          multiSelect={props.question.multiSelect}
          seconds={exerciseTime}
          pointsScored={pointsScored}
          questionNo={props.question.id}
          showCounter={timebound}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    points:
      state.responses.length === 0
        ? 0
        : state.responses
            .map((res) => res.points)
            .reduce((sum, agg) => sum + agg, 0),
  };
};

export default connect(mapStateToProps)(AssessmentCard);

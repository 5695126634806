import * as actions from "./actions";
const initialState = {
  time: "",
  start: false,
};
// initial State = [ time: "", start: false]
export default function (state = initialState, action) {
  switch (action.type) {
    case actions.START_TIMER:
      return { time: 0, start: true };

    case actions.RESET_TIMER:
      return initialState;

    case actions.STOP_TIMER:
      return { time: action.payload, start: false };

    default:
      return state;
  }
}

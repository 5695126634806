import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/pdf.module.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { staticImages, athete } from "../../../utils";
import { connect } from "react-redux";
import firebase from "../../../shared_elements/firebase/firebase";
import { getCookie } from "../../../shared_elements/cookie";
import { withRouter } from "react-router-dom";

function PdfView(props) {
  const id = props.match.params.id;
  const [responses, SetResponses] = useState(
    props.responses.length !== 0 ? props.responses : []
  );
  const [assessmentData, SetassessmentData] = useState("");
  const uid = getCookie("uid");

  useEffect(() => {
    if (!id && props.responses && props.responses.length === 0) {
      props.history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (id && uid) {
      const userRef = firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("assessments")
        .doc(id);
      userRef.get().then((docSnap) => {
        if (docSnap.exists) {
          const responseData = docSnap.data();
          if (responseData) {
            const response = responseData.response;
            SetResponses(response);
            const { totalPoints, atlete, day, date, time } = responseData;
            props.dateOf(date);
            SetassessmentData({
              totalPoints,
              atlete,
              day,
              date,
              time,
            });
          } else {
            // console.error("data not found");
          }
        } else {
          // console.error("No document of user found to delete it");
        }
      });
    } else {
      // console.error("user not logged in or id not found");
    }
  }, [id, uid]);
  const {
    lastDateAsses,
    lastTimeAsses,
    name,
    gender,
    lastAthlete,
  } = props.userdetail;

  props.dateOf(lastDateAsses);

  return (
    <section className={styles.pdf}>
      <div className={styles.sheet}>
        <div className={styles.sheetContainer}>
          <div className={styles.leftHeader}>
            <h3 className={styles.para}>Myositis Assessment Report</h3>
            <h1 className={styles.heading1}>{name ? name : ""}</h1>
            <p className={styles.label}>
              {assessmentData && assessmentData.date
                ? assessmentData.date
                : lastDateAsses}{" "}
              |{" "}
              {assessmentData && assessmentData.time
                ? assessmentData.time
                : lastTimeAsses}
            </p>
          </div>

          <div className={styles.rightHeader}>
            <h1 className={styles.heading1}>
              {assessmentData ? assessmentData.totalPoints : props.points}/52
            </h1>
            <p className={styles.label}>Total Score</p>

            <h3 className={`${styles.para} ${styles.athlete}`}>
              {name ? name : ""} is{" "}
              {assessmentData && assessmentData.atlete
                ? assessmentData.atlete
                : lastAthlete
                ? lastAthlete
                : athete(props.points, gender)}
            </h3>
          </div>

          <div className={styles.table}>
            <div className={styles.divider}></div>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <p className={styles.boldLabel}>Exercise</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={styles.boldLabel}>Analysis</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className={styles.boldLabel}>Point</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {responses.map((data) => (
                  <TableRow key={data.questionName}>
                    <TableCell align="left" component="th" scope="row">
                      <p className={styles.boldLabel}>{data.questionName}</p>
                    </TableCell>
                    <TableCell align="left">
                      <p className={styles.label}>
                        {data.userResponse.join(",")}
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <p className={styles.boldLabel}>{data.points}</p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className={styles.footer} style={{ marginTop: 150 }}>
            <img
              src={staticImages.logoBlack}
              alt="logoblack"
              className={styles.icon}
            />
            <span className={styles.textWarning}>
              <p className={styles.boldLabel}>
                THIS IS NOT A CLINICAL ASSESSMENT
              </p>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    points:
      state.responses.length === 0
        ? 0
        : state.responses
            .map((res) => res.points)
            .reduce((sum, agg) => sum + agg, 0),
    userdetail: state.userdetail,
    responses: state.responses,
  };
};

export default connect(mapStateToProps)(withRouter(PdfView));

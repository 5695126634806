import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { Content } from "./multiLanguage/containers/language";
import { Link } from "react-router-dom";

function CopyRights(props) {
  const matches = useMediaQuery("(min-width:960px)");
  return (
    <Grid
      container
      justify={matches ? "flex-start" : "flex-end"}
      className={`${props.visible ? "" : "disableCard"}`}
    >
      <Grid item md={12} sm={12} xs={12}>
        <Link className="policy" to="/terms-&-condition">
          <p className="policy">Contact · Terms & Conditions</p>
        </Link>
        <Link className="policy" to="/privacy-policy">
          <p className="policy"> . Privacy Policy</p>
        </Link>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <p className="copyright">
          <Content path="routes.home.copy_right.owner" />
        </p>
        <p className="copyright">
          © Design and Developed by{" "}
          <a
            href="https://www.designstring.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="anchor"
          >
            <b> DesignString </b>
          </a>
        </p>
      </Grid>
    </Grid>
  );
}

CopyRights.propTypes = {
  visible: PropTypes.bool,
};

CopyRights.defaultProps = {
  visible: true,
};

export default CopyRights;

import { combineReducers } from "redux";
import responsesReducer from "../shared_elements/redux/responses/reducer";
import userdetailReducer from "../shared_elements/redux/userdetails/reducer";
import timerReducer from "../shared_elements/redux/timer/reducer";
const rootReducer = combineReducers({
  responses: responsesReducer,
  userdetail: userdetailReducer,
  timer: timerReducer,
});
export default rootReducer;

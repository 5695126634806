import UserScoreCard from "../components/UserScoreCard";
import { Grid, Container } from "@material-ui/core";
import React, { Component } from "react";
import { MobileView } from "../../../shared_elements";

export default class UserScorePage extends Component {
  render() {
    return (
      <section className="userDetailCard">
        <Container maxWidth="md">
          <MobileView>
            <Grid container>
              <Grid item xs={12} className="userScoreTab">
                <UserScoreCard />
              </Grid>
            </Grid>
          </MobileView>
        </Container>
      </section>
    );
  }
}

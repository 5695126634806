import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";

function Timer(props) {
  const [seconds, SetSeconds] = useState(0);
  const [minutes, SetMinutes] = useState(0);
  const [stringSecond, SetStringSecond] = useState("00");
  const [stringMinute, SetStringMinutes] = useState("00");
  const { start } = props.timer;

  useEffect(() => {
    let timer;
    if (start) {
      timer = setTimeout(() => {
        if (seconds !== 59) {
          SetSeconds(seconds + 1);
          const newSeconds =
            seconds > 8 ? String(seconds + 1) : "0".concat(seconds + 1);
          SetStringSecond(newSeconds);
        } else {
          SetSeconds(0);
          SetStringSecond("00");
          SetMinutes(minutes + 1);
          const newMinutes =
            minutes > 8 ? String(minutes + 1) : "0".concat(minutes + 1);
          SetStringMinutes(newMinutes);
        }
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [start, seconds, minutes]);

  return (
    <Grid container direction="column" justify="flex-start">
      <Grid item>
        <h5 className="heading5">{`${stringMinute}:${stringSecond}`}</h5>
      </Grid>
      <Grid item>
        <h5 className="smallLabel">Total time</h5>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    timer: state.timer,
  };
};

export default connect(mapStateToProps)(Timer);

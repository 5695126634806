import React from "react";
import styled from "styled-components";
import { staticImages } from "../utils/";

const Icon = styled.div`
  background-image: url(${(props) => `${props.imgurl}`});
  background-position: center;
  background-size: cover;
  width: ${(props) => `${props.width}`}px;
  height: ${(props) => `${props.height}`}px;
  text-align: center;
  position: relative;
`;

const NumberData = styled.p`
  font-size: ${(props) => `${props.fontsize}`}px;
  font-weight: 700;
  font-family: Ubuntu;
  color: ${(props) => `${props.color}`};
  padding-top: ${(props) => `${props.paddingtop}`}%;
  padding-left: ${(props) => `${props.paddingleft}`}%;
  letter-spacing: ${(props) => `${props.letterspacing}`}px;
  z-index: 99;
  text-align: center;
`;

const TextData = styled.p`
  font-size: ${(props) => `${props.fontsize}`}px;
  font-weight: ${(props) => `${props.fontWeight}`};
  color: ${(props) => `${props.color}`};
  padding-top: ${(props) => `${props.paddingtop}`}%;
  padding-left: ${(props) => `${props.paddingleft}`}%;
  letter-spacing: ${(props) => `${props.letterspacing}`}px;
  line-height: ${(props) => `${props.lineHeight}`}px;
  z-index: 99;
  font-family: Ubuntu;
  font-style: normal;
  text-align: center;
  letter-spacing: -0.01em;
  width: 100%;
  display: block;
  color: #ffffff;
`;

function Calendar(props) {
  return (
    <>
      <Icon
        imgurl="https://firebasestorage.googleapis.com/v0/b/myopal-c8be3.appspot.com/o/staticimages%2Fcalenar.png?alt=media&token=a03524b0-c7d1-4469-8e21-a67310c516a5 "
        width="26"
        height="27"
      >
        <NumberData fontsize={14} color="#394652" paddingtop="20">
          {props.content}
        </NumberData>
      </Icon>
    </>
  );
}

function Timer(props) {
  return (
    <>
      <Icon imgurl={staticImages.timer3} width="147" height="48">
        <NumberData
          fontsize={36}
          color="##000000"
          paddingtop="7"
          letterspacing="32"
          paddingleft="7"
        >
          {props.content}
        </NumberData>
      </Icon>
    </>
  );
}

function CongratsIcon(props) {
  const { name } = props;
  return (
    <>
      <Icon
        imgurl={staticImages.particals}
        width={435}
        height={205}
        paddingtop={1}
      >
        <TextData
          fontsize={32}
          paddingtop="19"
          fontWeight={500}
          lineHeight={40}
        >
          Congratulations{" "}
          {name && name.length !== 0 ? " " + name.split(" ")[0] : " "}
        </TextData>
        <TextData fontsize={18} fontWeight={400}>
          You’re <u>{props.atlete}</u>
        </TextData>
      </Icon>
    </>
  );
}

function CongratsIconSmall(props) {
  return (
    <>
      <Icon
        imgurl={staticImages.particals}
        width={323}
        height={175}
        paddingtop={1}
      >
        <TextData
          fontsize={24}
          paddingtop="22"
          fontWeight={500}
          letterspacing={12}
          lineHeight={30}
        >
          Congratulations{" "}
          {props.name && props.name.length !== 0
            ? props.name.split(" ")[0]
            : props.name}
        </TextData>
        <TextData fontsize={18} fontWeight={400} letterspacing={12}>
          You’re <u>{props.atlete}</u>
        </TextData>
      </Icon>
    </>
  );
}

export { Calendar, Timer, CongratsIcon, CongratsIconSmall };

import fr from './fr.json';
import en from './en.json';
import kn from './kn.json';

export const dictionaryList = { en, fr, kn };

export const languageOptions = {
    en: 'English',
    fr: 'French',
    kn: 'Kannada'
};
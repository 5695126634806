import SignInPage from "./containers/SignInPage";
import SuggestNearbyDoctorPage from "./containers/SuggestNearbyDoctorPage";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";
export const signInRoutes = [
  {
    path: "/sign-in",
    component: pageLayoutHoc(SignInPage),
    key: "signin",
  },
  {
    path: "/doctor",
    component: pageLayoutHoc(SuggestNearbyDoctorPage),
    key: "doctor",
  },
];

import React, { useEffect } from "react";
import styles from "../../../assets/styles/homepage.module.scss";
import { Grid, Button } from "@material-ui/core";
import { staticImages } from "../../../utils";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { CLEAR_RESPONSE } from "../../../shared_elements/redux/responses/actions";

function WelcomeCard(props) {
  const { name, age, gender } = props.userdetail;

  useEffect(() => {
    if (props.responses.length !== 0) {
      props.dispatch({ type: CLEAR_RESPONSE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.welcomeCard}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item md={12} className={styles.description}>
          <h1 className="heading1">{props.title}</h1>
          <p className={`${styles.paragraph} paragraph`}>{props.content}</p>
          <div className="refer">
            <Link to="/about">(refer about us)</Link>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className={`flexCentered ${styles.bottomBtnGroup}`}
        alignItems="center"
        spacing={1}
      >
        <Grid item md={2} xs={2} sm={2} className={styles.clinicDescription}>
          <img
            src={staticImages.medical}
            alt="med warning"
            className={styles.clinicIcon}
          ></img>
        </Grid>

        <Grid item md={10} xs={12} sm={10} className={`${styles.assDetail}`}>
          <p className="label">{props.medicalWarning}</p>
          <Link to="/faq" className="link">
            <p className={`${styles.bold} label`}>
              {" "}
              {props.link} ,{""}
            </p>
          </Link>

          <a
            className="link"
            href="https://www.niehs.nih.gov/research/resources/assets/docs/cmas_instructions_508.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className={`${styles.bold} label`}> {props.downloadTest}</p>
          </a>
        </Grid>
        <Grid item md={12} sm={12} xs={12} className={styles.startBtnContainer}>
          {name && age && gender ? (
            <Link to="/assessment">
              <Button className="primaryBtn">{props.btnName}</Button>
            </Link>
          ) : (
            <Link to="/user-detail">
              <Button className="primaryBtn">{props.btnName}</Button>
            </Link>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userdetail: state.userdetail,
    responses: state.responses,
  };
};

export default connect(mapStateToProps)(WelcomeCard);
